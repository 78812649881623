import { Box, Text, Image } from '@chakra-ui/react';
import React from 'react';
import { MainContainer } from '../../components';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

const Unlocking = () => {
  return (
    <>
      <Box as="section" id="opportunity" mt="3rem">
        <Box>
          <Image
            src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697472981/company-image/Company-img_oe2ymq.jpg`}
            height={{ base: '60vh', lg: '600px' }}
            width="100%"
            alt="people"
          />
        </Box>

        <MainContainer>
          <Text
            as="p"
            my="1rem"
            fontSize={{ base: '22px', md: '26px' }}
            textAlign="center"
            fontFamily="Trocchi"
            letterSpacing="1.17px"
            lineHeight="50px"
          >
            "Unlocking the boundless opportunities in AI to transform businesses
            and shape the future."
          </Text>
        </MainContainer>
      </Box>
    </>
  );
};

export default Unlocking;
