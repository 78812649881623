import React from 'react';
import 'animate.css';
import { Link } from 'react-router-dom';
import GetStartedModal from './GetStartedModal';

function ContactUs() {
  return (
    <>
      <div className="contactPage">
        <div className="cont">
          <div className="container">

            <div className="row">
              <div className="col-md-6 mb-3">
                <GetStartedModal />
              </div>
              {/* <div className="col-md-2"></div> */}
              <div className="col-md-6 mb-3">
                <Link to='/getintouch'>
                  <center>
                    <div className="heard">
                      <button className='btnsGreen'>Contact us  </button>
                    </div>
                  </center>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
