import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
AOS.init()


const theme = extendTheme({
  colors: {
    white: '#FFFFFF',
    green: {
      50: 'rgba(4, 48, 48, 0.90)',
      100: '#5D8E85',
      200: '#043030E5',
      300: '#0A3434',
      400: '#459E9E',
      500: '#059669',
    },
    black: {
      50: '#000000',
    },
    gray: {
      50: '#FAFAFA',
    },
  },
  breakpoints: {
    sm: '576px',
    md: '700px',
    xmd: '800px',
    lg: '991px',
    xl: '1200px',
    xxl: '2000px',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
