import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { MainContainer } from '../../components';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';
import LazyImage from '../../components/LazyImage';

export const HeadingTwo = ({ text }) => (
  <Heading
    as="h2"
    fontFamily="Trocchi"
    fontSize="26px"
    fontWeight="400"
    lineHeight="30px"
    mb="10px"
  >
    {text}
  </Heading>
);

const Mission = () => {
  return (
    <Box
      as="section"
      id="mission"
      pt="30px"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <MainContainer>
        <Box
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={{ base: '20px', md: '0' }}
        >
          <Box textAlign="center" w={{ base: '100%', md: '55%' }}>
            <HeadingTwo text="Mission" />

            <Text
              as="p"
              fontFamily="Roboto Condensed"
              fontSize="22px"
              lineHeight="50px"
            >
              At AI Pathfinder, we catalyze small business success through AI
              innovation. By crafting tailored AI solutions, we streamline
              operations, elevate decision-making, and amplify customer
              engagement. Our mission is to democratize AI's potential, enabling
              small businesses to flourish in a dynamic digital landscape.
            </Text>
          </Box>

          <Box w={{ base: '100%', md: '30%' }}>
            <LazyImage
              src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473032/company-image/mission-img_gveq8t.jpg`}
              style={{ marginRight: 'auto', marginLeft: 'auto' }}
              height="380px"
              alt="mission-img"
            />
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Mission;
