import React from 'react';
import {
  Box,
  Grid,
  Image,
  Text,
  Heading,
  GridItem,
} from '@chakra-ui/react';
import { offer } from '../../config/data';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { MainContainer } from '../../components';

const Offer = () => {
  return (
    <Box as="section" id="offer">
      <MainContainer
      >
        <Box display="flex" justifyContent="center">
          <Heading
            as="h2"
            bgColor="#FAFAFA"
            width="fit-content"
            fontFamily="Trocchi"
            fontSize="26px"
            fontWeight="400"
            lineHeight="60px"
            px="10%"
            mb="1.5rem"
          >
            WHAT WE OFFER
          </Heading>
        </Box>
        <Box>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(4 1fr)',
            }}
            rowGap="30px"
            gap={{ base: '40px', md: '35px' }}
            my="2rem"
          >
            {offer.map((item) => {
              return (
                <GridItem className="card-container" key={item.id}>
                  <Box
                    className="card"
                    minW={{ base: '90%', md: '270px' }}
                    maxW={{ base: '90%', md: '270px' }}
                    mx="auto"
                    position="relative"
                    height="400px"
                    boxShadow="0 0 5px 2px rgb(192, 188, 188)"
                  >
                    <Box className="front">
                      <Box
                        className="card-content"
                        overflow="hidden"
                        boxShadow="0 0 5px 2px rgb(192, 188, 188)"
                      >
                        <Image
                          src={item.image}
                          alt={item.title}
                          className="card-content-img"
                          position="relative"
                          zIndex="10"
                          width={{ base: '100%', md: '270px' }}
                          height="270px"
                          mx="auto"
                          transform="scale(1)"
                          transition="transform .3s ease-in-out"
                        />

                        <Box
                          bgColor="white"
                          w="100%"
                          mt="-30px"
                          zIndex="10"
                          position="relative"
                          minHeight="170px"
                          px="30px"
                          py="18px"
                          className="card-content-text"
                        >
                          <Box
                            fontSize="18px"
                            fontWeight="400"
                            lineHeight="30px"
                            fontFamily="Trocchi"
                            minHeight="75px"
                            mb="10px"
                          >
                            {item.title}
                          </Box>

                          <Box display="flex" alignItems="center" gap="8px">
                            <Text as="span">Find out More</Text>{' '}
                            <Text as="span">
                              <ArrowForwardIcon
                                w="24px"
                                h="24px"
                                className="arrow-right"
                              />{' '}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="back">
                      <Box
                        as="p"
                        fontSize="18px"
                        lineHeight="30px"
                        fontFamily="Roboto Condensed"
                        textAlign="center"
                        mt="1rem"
                      >
                        {item.text}
                      </Box>
                    </Box>
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Offer;
