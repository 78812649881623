import React from 'react';
import { Box, Container, Link } from '@chakra-ui/react';

const SectionLink = ({ title, path }) => (
  <Link
    textDecoration="none"
    color="green.100"
    fontFamily="Trocchi"
    fontWeight='500'
    fontSize={{ base: '14px', md: "18px" }}
    textAlign="center"
    lineHeight={{ base: '40px', md: "90px" }}
    href={path}
    scrollBehavior="smooth"
    _hover={{
      textDecoration: 'none',
    }}
  >
    {title}
  </Link>
);

const SectionBar = () => {
  return (
    <Box
      position="sticky"
      bgColor={{ base: '#fff', md: 'transparent' }}
      top={{ base: '70px', md: '76px' }}
      zIndex="20"
      marginTop="-30px"
    >
      <Container
        width="100%"
        maxW={{ base: '100%', md: '90%', xl: '1200px' }}
        m="0 auto"
        p={{ base: '0rem', md: '0rem 1rem', xl: '0rem 2rem' }}
      >
        <Box
          height="90px"
          display="flex"
          flexWrap="wrap"
          maxW={{ base: '100%', lg: '70%' }}
          px={{ base: '20px', md: '70px' }}
          mx="auto"
          bgColor="#FFFFFF"
          // position="relative"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          // bottom="5rem"
          zIndex="100"
          // gap="10px"
          boxShadow=" 0px 4px 50px 0px rgba(85, 175, 175, 0.46)"
        >
          {[
            { name: 'Mission', path: '#mission' },
            { name: 'Vision', path: '#vision' },
            { name: 'Our Values', path: '#values' },
            { name: 'Opportunity', path: '#opportunity' },
          ].map((item, key) => (
            <SectionLink key={key} title={item.name} path={item.path} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default SectionBar;
