import React from 'react';
import { Box, Container, Image, Heading } from '@chakra-ui/react';
import { networks } from '../../config/data';
import Marquee from 'react-fast-marquee';

const MarqueeContainer = () => {
  const W = window.innerWidth - 17
  const width = W+"px"

  return (
    <Box as="section">
      <Container
        maxW='100%'
        m="0 auto"
        p={{
          base: '1rem 0 0.5rem 1rem',
          md: '2rem 0 0.5rem 2rem',
          xl: '2rem 0 0.5rem 2rem',
        }}
        w="100%"
      >
        <Box
          display="flex"
          flexDirection='column'
          alignItems="center"
        >
          <Box>
            <Heading
              as="h2"
              color="#000"
              fontFamily="Trocchi"
              fontSize={{ base: '24px', md: '24px' }}
              textAlign="center"
              fontWeight="400"
              mb="2rem"
            >
              Our Network
            </Heading>
          </Box>

          <Box display="flex" width={width} flexDirection="column" gap="35px">
            <Marquee
            direction='right'
            speed="30"
            width="100px"
            >
              {networks.map((network) => (
                <div className='Networkdiv'     >
                  <center>
                    <Image
                      key={network.id}
                      width='100%'
                      // backgroundColor="#fff"
                      // borderRadius="10px"
                      // padding="15px"
                      // boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                      // height="65px"
                      src={network.image}
                      alt={network.title}
                    />
                  </center>
                </div>
              ))}
            </Marquee>
            <Marquee 
             speed="30"
            >
              {networks.map((network) => (
                <div className='Networkdiv'     >
                  <center>
                    <Image
                      key={network.id}
                      width='100%'
                      src={network.image}
                      alt={network.title}
                    />
                  </center>
                </div>
              ))}
            </Marquee>

            {/* <Marquee>
              {networksTwo.map((network) => (
                <Image
                  key={network.id}
                  mx="0.8rem"
                  width={{ base: '100px', md: '135px' }}
                  height="65px"
                  src={network.image}
                  alt={network.title}
                />
              ))}
            </Marquee> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MarqueeContainer;
