import React from 'react'
import LinkNav from '../../components/LinkNav'
import Img1 from '../../assets/images/partners/pexels-fauxels-3184291.jpg'
import Img2 from '../../assets/images/partners/2909363-200.png'
import Img3 from '../../assets/images/partners/download.jpeg'
import Img4 from '../../assets/images/partners/download.png'
import Img5 from '../../assets/images/partners/ddd.png'
import { Link } from 'react-router-dom'
// import Img5 from '../../assets/images/partners/pexels-gratisography-519.jpg'

function PartnerPage() {
    return (
        <div>
            <div className="partners">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/partner"
                    Second="Partners"
                />
                <div className="bannerr1">
                    <h2 className='animate__animated animate__bounce'>PARTNERS</h2>
                </div>
                <div className="margin50"></div>
                <div className="secttion1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={Img1} alt="" />
                            </div>
                            <div className="col-md-7">
                                <h2 className='mb-3'>Collaborating for AI Success</h2>
                                <p>AI Pathfinder is proud to partner with a diverse network of organizations and individuals who share our vision of democratizing the potential of artificial intelligence for small businesses. Our partners play a crucial role in advancing our mission and empowering businesses with AI solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="secttion2">
                    <div className="container">
                        <div className="">
                            <h1>Why Partner with AI Pathfinder?</h1>
                            <div className="row mt-4">
                                <div className="col-md-6 mb-3">
                                    <div className="text">
                                        <span><img src={Img2} alt="" /></span>
                                        <h2 className='mb-2'>Empower Small Businesses:</h2>
                                        <p>By partnering with us, you contribute to the growth and success of small businesses. AI Pathfinder's tailored AI solutions are designed to make a meaningful impact.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="text">
                                        <center><span><img src={Img3} alt="" /></span></center>
                                        <h2 className='mb-2'>Access to AI Expertise:</h2>
                                        <p>Collaborate with a community of AI experts and enthusiasts. Gain access to cutting-edge AI knowledge and solutions that can transform industries.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="text">
                                        <span><img src={Img4} alt="" /></span>
                                        <h2 className='mb-2'>Innovation and Impact:</h2>
                                        <p>Join a mission-driven community that's focused on pushing the boundaries of AI. Together, we innovate and create solutions that lead to real-world impact.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="text">
                                        <span><img src={Img5} alt="" /></span>
                                        <h2 className='mb-2'>Networking Opportunities:</h2>
                                        <p>Our partners have the opportunity to connect with like-minded individuals, businesses, and organizations in the tech and AI field. Networking with our community can lead to exciting opportunities and collaborations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="secttion3">
                    <div className="container">
                        <h1>Types of Partnerships</h1>
                        <p>AI Pathfinder offers various partnership opportunities for organizations and individuals:</p>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <div className="type1">
                                    <h2>Technology Partners:</h2>
                                    <p>Collaborate with us to integrate your AI technologies and solutions into our offerings, enhancing our capabilities and providing more value to small businesses.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="type2">
                                    <h2>Content Partners:</h2>
                                    <p>Partner with us to create and share educational content, including articles, webinars, and tutorials, to empower our community with AI knowledge.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="type3">
                                    <h2>Community Partners:</h2>
                                    <p> Work with us to foster a thriving AI community. Co-organize events, webinars, and discussions to engage our members and promote knowledge-sharing.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="type4">
                                    <h2>Research Partners: </h2>
                                    <p>Join us in advancing AI research and innovation. Collaborate on research projects and initiatives that drive AI progress.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="type5">
                                    <h2>Financial Partners:</h2>
                                    <p> We are grateful for the support of our financial partners who provide the resources necessary to carry out our mission effectively. Your financial partnership helps us reach more small businesses and make a greater impact in the AI space.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secttion4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="text">
                                    <p>AI Pathfinder is grateful for the support and collaboration of our current partners. Together, we're making AI accessible and driving innovation. </p>
                                </div>
                            </div>
                            <div className="col-md-7"></div>
                        </div>
                    </div>
                </div>
                <div className="secttion5">
                    <div className="container-xxl">
                    <div className="row">
                        <div className="col-md-6 p-0">
                            <div className="first">
                                <div className="line1"></div>
                                <h2>AI Pathfinder looks forward to collaborating with you and creating a brighter future for small businesses through the potential of artificial intelligence.</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="second">
                                <div className="line2"></div>
                                <h2>Become a Partner</h2>
                                <div className="mb-4"></div>
                                <p>If you share our vision and are interested in partnering with AI Pathfinder, we invite you to explore the opportunities and possibilities. Contact us to discuss how we can work together to empower small businesses with AI.
                                </p>
                                <button className='apply'>
                                    <Link to="/contact">Contact us</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerPage