import React from 'react'
import Img1 from '../../assets/images/Home/Vector.png'
import Img2 from '../../assets/images/Home/Image (8).png'


function Carousel() {
    return (
        <div>
            <div className="CarouselHome">
                <div id="carouselExampleDark" class="carousel carousel-dark slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="10000">
                            <div className="container">
                                <center>
                                    <div className="col-md-10">
                                        <img src={Img1} class="" alt="..." />
                                        <p>“Since I started implementing AIPathfinder's AI in my business, I have had an advantage over my competitors. It helps me detect frauds before it happens, and I no longer have to run helter skelter like my competitors do”</p>
                                    </div>
                                </center>
                                <center>
                                    <div class="">
                                        <img src={Img2} class="" alt="..." />
                                        <h5 className='m-0 mt-3'>Barry Emmanuella</h5>
                                        <p className='pt-0'>C.E.O @ IPI Enterprise</p>
                                    </div>
                                </center>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="10000">
                            <div className="container">
                                <center>
                                    <div className="col-md-10">
                                        <img src={Img1} class="" alt="..." />
                                        <p>“I was skeptical about tech at first, but thanks to AI Pathfinder’s program, I embraced online ordering and expanded my customer base. My store has never been busier!”</p>
                                    </div>
                                </center>
                                <center>
                                    <div class="">
                                        <img src={Img2} class="" alt="..." />
                                        <h5 className='m-0 mt-3'>Yemisi Emmanuella</h5>
                                        <p className='pt-0'>C.E.O @ Stanwick Enterprise</p>
                                    </div>
                                </center>
                            </div>
                        </div>
                        <div class="carousel-item " data-bs-interval="10000">
                            <div className="container">
                                <center>
                                    <div className="col-md-10">
                                        <img src={Img1} class="" alt="..." />
                                        <p>“AIPathfinders fraud detection algorithm has been a lifesaver for our small online marketplace. It has helped us reduce losses drastically”</p>
                                    </div>
                                </center>
                                <center>
                                    <div class="">
                                        <img src={Img2} class="" alt="..." />
                                        <h5 className='m-0 mt-3'>Bradley Cooper</h5>
                                        <p className='pt-0'>Managing Partner at C & C Venture</p>
                                    </div>
                                </center>
                            </div>
                        </div>

                        <div class="carousel-item " data-bs-interval="10000">
                            <div className="container">
                                <center>
                                    <div className="col-md-10">
                                        <img src={Img1} class="" alt="..." />
                                        <p>“I often experience huge debt in  my business but since I started integrating AIPathfinder's algorithm into my business, I have been able to save a lot  and my customers now trust me once again.”</p>
                                    </div>
                                </center>
                                <center>
                                    <div class="">
                                        <img src={Img2} class="" alt="..." />
                                        <h5 className='m-0 mt-3'>Xu Yo</h5>
                                        <p className='pt-0'>Engineer</p>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Carousel