import React from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

const Hero = () => {
  return (
    <Box position="relative" pt="7rem">
      <Box
        bgImage={
          "url('" +
          CLOUDINARY_DOMAIN_ACCOUNT +
          "/v1697472995/company-image/company-hero-img_llc2h4.jpg')"
        }
        bgRepeat="no-repeat"
        bgPosition="100%"
        bgSize="cover"
        minH="85vh"
      >
        <Box
          bgColor="#FAFAFA"
          opacity="0.5"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minH="85vh"
        >
          <Container
            width="100%"
            maxW={{ base: '100%', md: '90%', xl: '1200px' }}
            m="0 auto"
            p={{ base: '1rem 1rem', md: '2rem 1rem', xl: '2rem 2rem' }}
          >
            <Heading
              as="h1"
              color="#000"
              textAlign="center"
              fontFamily="Trocchi"
              fontSize={{ base: '20px', xmd: '32px' }}
              lineHeight={{ base: '60px', xmd: '90px' }}
              textTransform="capitalize"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-easing="linear"
            >
              We are on a relentless mission to empower businesses with the
              transformative potential of AI.
            </Heading>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
