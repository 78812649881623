import React from 'react'
import LinkNav from '../../components/LinkNav'
import Img1 from '../../assets/images/judge/pexels-towfiqu-barbhuiya-3440682-8693379.jpg'
import Img2 from '../../assets/images/judge/pexels-rdne-7005070.jpg'
import { Link } from 'react-router-dom'

function Judge() {
    return (
        <div>
            <div className="partners ">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/judge"
                    Second="Judge"
                />
                <div className="judgebanner">
                    <h2 className='animate__animated animate__bounce'>JUDGE</h2>
                </div>
                <div className="margin50"></div>
                <div className="container">
                    <p className='f20'>AI Pathfinder specializes in harnessing the power of Artificial Intelligence (AI) to drive innovation and fuel the growth and success of small businesses in Africa. Her mission is to provide cutting-edge AI solutions that not only enhance operational efficiency but also unlock new opportunities for small enterprises(SME’s). By integrating advanced AI technologies, we empower businesses to make data-driven decisions, streamline processes, and stay competitive in an ever-evolving marketplace.
 AI Pathfinder receives thousands of applications from small businesses across Africa each year. To effectively manage and evaluate these applications, AI Pathfinder is seeking Judging Experts in Artificial Intelligence to assist in screening and assessing the applications from SMEs.</p>
                </div>
                <div className="margin50"></div>
                <div className="secttion1">
                    <div className="container">
                        <center>
                            <div className="col-md-7">
                                <h2 className='mb-3'>Join Our Esteemed Panel of Judges</h2>
                                <p className='f20'>Are you passionate about evaluating nominations from successful companies? Join our distinguished panel of judges and make a significant impact by reviewing and scoring entries online. As a judge, you'll have the unique opportunity to:</p>
                            </div>
                        </center>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="secttion2">
                    <div className="container">
                        <div className="">
                            <div className="row mt-4">
                                <div className="col-md-4 mb-3">
                                    <div className="greencard text">
                                        <h2 className='mb-2'>Empower Small Businesses:</h2>
                                        <p>By partnering with us, you contribute to the growth and success of small businesses. AI Pathfinder's tailored AI solutions are designed to make a meaningful impact.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="greencard text">
                                        <h2 className='mb-2'>Access to AI Expertise:</h2>
                                        <p>Collaborate with a community of AI experts and enthusiasts. Gain access to cutting-edge AI knowledge and solutions that can transform industries.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="greencard text">
                                        <h2 className='mb-2'>Innovation and Impact:</h2>
                                        <p>Join a mission-driven community that's focused on pushing the boundaries of AI. Together, we innovate and create solutions that lead to real-world impact.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="awardsection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={Img2} alt="" />
                            </div>
                            <div className="col-md-6">
                                <div className="text">
                                    <div className="first">
                                        <h2>JUDGING PROCESS</h2>
                                        <p><b>MORE THAN 25,000 PROFESSIONALS FROM ALL OVER THE WORLD HAVE SIGNED UP TO PARTICIPATE AS 2024 JUDGES! YOU CAN JOIN TOO!</b></p>
                                        <p>We invite working professionals and business leaders to become an integral part of the selection process and contribute to the industry’s most esteemed awards program. As a judge, you’ll have the opportunity to evaluate and recognize exceptional achievements, playing a vital role in identifying deserving winners. Your expertise and industry insights will help shape the landscape of recognition, ensuring that the best of the best are celebrated. Don’t miss this chance to be a part of an industry-driven process and make a lasting impact. Sign up today and lend your expertise to the Globee Awards judging panel!</p>
                                        <a href="https://globeeawards.com/judging-and-awards-process/" className='btnsDark white'> Read more </a>
                                    </div>
                                    <div className="mb-3"></div>
                                    <div className="borderTopGreen"></div>
                                    <div className="first">
                                        <h2>WHAT JUDGES HAVE TO SAY</h2>
                                        <p><b>Hawanem Omara</b></p>
                                        <p>I had the privilege of serving as a judge for the esteemed Globee Awards for Women in Business. Reviewing and scoring nominations from talented women worldwide was an absolute pleasure. It was truly inspiring to witness their exceptional achievements and groundbreaking ideas. Being part of the judging panel allowed me to contribute to recognizing their…</p>
                                        <a href="https://globeeawards.com/about/what-judges-have-to-say/" className='btnsDark white'> Read more </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="secttion5">
                    <div className="container">
                        <div className="col-md-8">
                            <div className="second pt-4">
                                <div className="line2"></div>
                                <h2>Become a Judge</h2>
                                <div className="mb-4"></div>
                                <p className='f20'>Become a part of our judging community and play a crucial role in celebrating success and innovation. Your participation helps uphold the high standards and integrity of our awards program. Join us and make a difference today!
                                </p>
                                <Link to="/judge/auth">
                                <button className='apply'>
                                    Apply to be a judge at AI Pathfinder
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Judge