import React from 'react'
import { Link } from 'react-router-dom';

function LinkNav(props) {
  return (
    <>
        <div className="">
            <div className="padding50"></div>
            <div className="LinkNav">
                <ul>
                    <li> <Link to="/"> Home </Link> </li>
                    <li>|</li>
                    <li className={props.FirstUrl} > {props.First} </li>
                    <li>{props.Dash}</li>
                    <li> <Link to={props.SecondUrl} > {props.Second} </Link> </li>
                </ul>
            </div>
        </div>
    </>
  )
}

export default LinkNav

