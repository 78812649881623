import React from 'react'
// import { Link } from 'react-router-dom';
import LinkNav from '../../components/LinkNav';
import 'animate.css';
import Img1 from '../../assets/images/about/istockphoto-685797134-612x612.jpeg'
import Img2 from '../../assets/images/about/pexels-burak-kostak-18809.jpg'
import Values from '../company/Values';
import StayConected from '../../components/StayConected';
import ContactUs from '../../components/ContactUs';


function AboutUs() {
    return (
        <>
            <div className="about">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/about"
                    Second="About us"
                />
                <div className="">
                    <div className="bannerr1">
                        <h2 className='animate__animated animate__bounce'>ABOUT US</h2>
                    </div>
                    <div className="margin50"></div>
                    <div className="sectionn1">
                        <div className="container">
                            <div className="row mb-4">
                                <div className="col-md-4 mb-3">
                                    <img src={Img1} alt="" />
                                </div>
                                <div className="col-md-8 mb-3">
                                    <h2> Mission</h2>
                                    <p>At AIPathfinder, we catalyze small business success through AI innovation. By crafting tailored AI solutions, we streamline operations, elevate decision-making, and amplify customer engagement. Our mission is to democratize AI's potential, enabling small businesses to flourish in a dynamic digital landscape.</p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-8 mb-3">
                                    <h2> The Vision </h2>
                                    <p>Our vision is to usher in a future where every small business, regardless of size or industry, harnesses the transformative power of artificial intelligence to thrive and lead in an increasingly digital world. 
                                        
                                        <p> </p>We envision a landscape where AI seamlessly integrates into daily operations, enhancing efficiency, productivity, and innovation across the board. Our commitment is to be the guiding light, the pioneer, and the trusted partner for small businesses on their journey to unlock AI's limitless possibilities, making the once complex, simple, and the unattainable, achievable.</p>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <img src={Img2} alt="" />
                                </div>
                            </div>
                            <div className="margin50"></div>

                            <div className="who">
                                <div className="row mb-4">
                                    <div className="col-md-4">
                                        <div className="img3"></div>
                                        {/* <img src={Img3} alt="" className='img3'/> */}
                                    </div>
                                    <div className="col-md-8 p-5 pt-4 ps-2">
                                        <div className="mb-3">

                                            <h1>Who We Are</h1>
                                        </div>
                                        <div className="ps-5">
                                            <div className="mb-3">
                                                <h2 className='mb-3'> Pioneers in AI Expertise:</h2>
                                                <p>AI Pathfinder boasts a team of highly skilled Pathfinders, consisting of AI and tech experts who have a deep understanding of artificial intelligence technologies and their practical applications in various industries. Our Pathfinders are pioneers and volunteers from different fields in big tech, ranging from Amazon to Google, Apple, Microsoft, and many more.</p>
                                            </div>
                                            <div className="mb-3">
                                                <h2 className="mb-3">Community-Driven:</h2>
                                                <p>We are more than a company; we are a thriving community of AI enthusiasts, professionals, and learners. Our members collaborate, share knowledge, and embark on AI projects that drive real-world impact.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="">
                        <Values />
                    </div>
                    <div className="margin50"></div>
                    <div className="sectionn2">
                        <div className="container">
                            <div className="one">
                                <div className="row">
                                    <div className="col p-4">
                                        <h2 className=''>Unlocking the boundless opportunities in AI to transform businesses and shape the future.</h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="margin50"></div>
                    <div className="sectionn3">
                        <div className="container">
                            <div className="mb-5">
                                <center>
                                    <div className="col-md-9">
                                        {/* <p> .</p> */}
                                        <p>The opportunity for AI to revolutionize small businesses lies in its potential to enhance efficiency, boost decision-making, and elevate customer engagement, paving the way for unprecedented growth in the digital era.</p>
                                    </div>
                                </center>
                            </div>
                            <h2 className='mb-3 opp'> The Opportunity </h2>
                            <p>AIPathfinder has a unique opportunity to empower small businesses by creating a holistic Small Business AI Empowerment Ecosystem. This ecosystem will provide small businesses with the knowledge, tools, and support needed to seamlessly integrate artificial intelligence into their operations, enabling them to thrive and lead in an increasingly digital world. By offering AI integration workshops, an AI solution marketplace, consultation services, a community hub, and more, AIPathfinder aims to democratize AI's potential, making it accessible and transformative for small businesses across industries.</p>
                            <div className="margin50"></div>
                            <h2 className='mb-3 opp'> What we offer </h2>
                            <p>To fulfill the vision of AIPathfinder and catalyze small business success through AI innovation, we have created an expansive opportunity called the "Small Business AI Empowerment Ecosystem." This ecosystem is designed to empower small businesses through tailored AI solutions, streamlining operations, elevating decision-making, and amplifying customer engagement. <b>Here's how we offer this solution:</b></p>
                            <div className="list mt-5">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <h2>AI Integration Workshops and Training Programs</h2>
                                        <p className='ms-3'>  We have developed comprehensive AI integration workshops and training programs specifically tailored for small business owners and employees. These programs will focus on demystifying AI, teaching practical implementation techniques, and providing hands-on experience with AI tools and platforms.</p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>AI Solution Marketplace</h2>
                                        <p className='ms-3'>  The AI solution marketplace is where small businesses can discover, purchase, or subscribe to AI applications and tools that cater to their unique needs. This marketplace features a curated selection of AI solutions designed to streamline operations, enhance decision-making, and boost customer engagement.</p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>AI Consultation Services</h2>
                                        <p className='ms-3'>  We offer personalized AI consultation services to small businesses, providing expert guidance on identifying AI opportunities within their operations, customizing AI solutions, and developing AI implementation roadmaps. These consultations are based on a deep understanding of each business's industry and specific challenges.</p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>Community and Networking Hub</h2>
                                        <p className='ms-3'>   We have created an online community and networking hub for small business owners and AI enthusiasts to connect, share experiences, and collaborate. This platform serves as a knowledge-sharing space where businesses can learn from each other and stay updated on the latest AI trends and best practices.</p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>AIPathfinder Certification Program</h2>
                                        <p className='ms-3'>  We will Launch a certification program that recognizes small businesses for their successful AI implementations. This program will provide a framework for businesses to showcase their AI-driven achievements, instilling confidence in customers and partners.</p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>AI for Social Impact Initiatives</h2>
                                        <p className='ms-3'>We encourage small businesses to use AI for social and environmental impact, aligning with responsible AI practices.
                                            We collaborate with businesses on AI-driven projects that address pressing social and environmental challenges, promoting ethical AI use.
                                        </p>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <h2>Research and Development Grants</h2>
                                        <p className='ms-3'>We offer research and development grants to incentivize innovation within the small business sector. These grants support AI-related projects that have the potential to drive industry-specific advancements and help businesses stay competitive.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <StayConected />
                    </div>
                    <div className="">
                        <ContactUs />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs

