import React from 'react'
import LinkNav from '../../components/LinkNav'
import ContactUs from '../../components/ContactUs'
import MarqueeContainer from '../LandingPage/Marquee'
import Img1 from '../../assets/images/pathfinder/pexels-fauxels-3228689.jpg'
import Img2 from '../../assets/images/pathfinder/pexels-christina-morillo-1181622.jpg'
// import Img3 from '../../assets/images/pathfinder/pexels-kampus-production-5940841.jpg'
import Img4 from '../../assets/images/pathfinder/pexels-rodolfo-quirós-2330137.jpg'
import Img5 from '../../assets/images/pathfinder/pexels-zain-ali-542619.jpg'
import Img6 from '../../assets/images/pathfinder/istockphoto-685797134-612x612.jpeg'
import { Volunteer } from './VolunteerData';


function Pathfinder() {
    return (
        <div>
            <div className="pathfinders">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/pathfinder"
                    Second="Pathfinders"
                />
                <div className="bannerr1">
                    <h2 className='animate__animated animate__bounce'>Meet the AI Pathfinder <span>Pathfinders</span></h2>
                    <p>A Dynamic Team of AI Innovators and Tech Pioneers</p>
                </div>
                <div className="margin50"></div>
                <div className="section11">
                    <div className="container">
                        <div className="border1">
                            <div className="row p-3">
                                <div className="col-md-1"></div>
                                <div className="col-md-4 mb-3">
                                    <img src={Img1} alt="" />
                                </div>
                                <div className="col-md-7 mb-3">
                                    <h2 className='mb-3'>Diversity in Unity</h2>
                                    <p>The strength of our team lies in its diversity. We bring together talents from various backgrounds, experiences, and corners of the globe. From engineers to data scientists, from seasoned AI experts to emerging tech enthusiasts, our Pathfinders enrich our community with their unique perspectives and skills.</p>
                                </div>
                            </div>
                        </div>
                        <div className="border2">
                            <div className="row p-3">
                                <div className="col-md-7 mb-3">
                                    <h2 className='mb-3 mb-3'>Expertise That Inspires</h2>
                                    <p>Our team members are not just experts; they are visionaries in the world of artificial intelligence. With deep-seated knowledge in AI domains such as machine learning, natural language processing, computer vision, and data analytics, our Pathfinders are on a constant quest for innovation.</p>
                                </div>
                                <div className="col-md-4">
                                    <img src={Img5} alt="" />
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                        <div className="border1">
                            <div className="row p-3">
                                <div className="col-md-1"></div>
                                <div className="col-md-4 mb-3">
                                    <img src={Img4} alt="" />
                                </div>
                                <div className="col-md-7 mb-3">
                                    <h2 className='mb-3'>Tech Giants Among Us</h2>
                                    <p>Our roster includes individuals who have contributed to tech giants such as Amazon, Google, Apple, Microsoft, and more. Their experience from these industry leaders provides us with insights, methodologies, and a level of excellence that sets us apart in the world of AI.</p>
                                </div>
                            </div>
                        </div>
                        <div className="border2">
                            <div className="row p-3">
                                <div className="col-md-7 mb-3">
                                    <h2 className='mb-3 mb-3'>The Pathfinders' Mission</h2>
                                    <p>At AI Pathfinder, we're united by a common mission: to empower small businesses with AI. We believe in the potential of AI to elevate decision-making, streamline operations, and enhance customer engagement. Our Pathfinders work tirelessly to ensure that AI is accessible to all, not just to corporations with vast resources.</p>
                                </div>
                                <div className="col-md-4">
                                    <img src={Img6} alt="" />
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                        <div className="border3">
                            <div className="row p-3">
                                <div className="col-md-1"></div>
                                <div className="col-md-4 mb-3">
                                    <img src={Img2} alt="" />
                                </div>
                                <div className="col-md-7 mb-3">
                                    <h2 className='mb-3'>Community-Driven Collaboration</h2>
                                    <p>We're not just a team; we're a thriving community of AI enthusiasts, professionals, and learners. Our culture of collaboration and knowledge-sharing is at the heart of our success. We actively engage in discussions, support one another, and pool our collective expertise to drive our mission forward.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50"></div>
                <div className="section12">
                    <div className="container">
                        <div className="text">
                            <h2>Why Volunteer with us ?</h2>
                            <center>  <p className='col-md-9'>At AI Pathfinder, we recognize the incredible value of volunteers in driving our mission to democratize AI. As a Pathfinder, you become an integral part of our dynamic community and gain access to a range of unique benefits:</p></center>
                        </div>
                        <div className="row mt-4">
                            {Volunteer?.map((item, key) => (
                                <div className="col-md-4" key={key} >
                                    <div className="carousel" >
                                        <h2>{item.header}</h2>
                                        <p>{item.body}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="">
                            <button className='apply'>Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="section13">
                    <div className="container">
                        <div className="text">
                            <h2>Join Our Team of Pathfinders and Make an Impact in Tech</h2>
                            <p>At AI Pathfinder, we are always on the lookout for passionate individuals who want to make a meaningful impact in the tech field. Our team is diverse, dynamic, and committed to democratizing the potential of artificial intelligence for small businesses. Explore the opportunities to volunteer with us and contribute to these categories within the tech industry:</p>
                        </div>
                        <h2 className='mb-4 mt-4'><b>Areas of Impact</b> </h2>
                        <div className="">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2>Machine Learning and Data Science</h2>
                                    <p className='ms-3'>If you are a machine learning enthusiast, data scientist, or engineer, AI Pathfinder offers an exciting platform to apply your expertise. Join our team to work on projects that leverage machine learning algorithms and data science techniques to solve real-world problems. Your contributions will help small businesses harness data-driven insights for growth and success.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Natural Language Processing (NLP) and Language Technologies</h2>
                                    <p className='ms-3'>Language technologies are transforming the way businesses interact with customers. AI Pathfinder is actively involved in NLP and language technologies, creating solutions that enable businesses to communicate more effectively. If you are passionate about language and tech, your skills can find a home here.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Computer Vision and Image Analysis</h2>
                                    <p className='ms-3'>Computer vision has applications across various industries, from healthcare to retail. AI Pathfinder is engaged in creating solutions that use computer vision and image analysis to extract valuable information from visual data. Join our team to shape the future of computer vision applications.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Cloud Computing and Infrastructure </h2>
                                    <p className='ms-3'>The backbone of AI applications lies in robust cloud infrastructure. AI Pathfinder is continually building and optimizing cloud-based systems to deliver AI solutions. If you are experienced in cloud computing and infrastructure, you can contribute to the scalability and reliability of our AI platforms.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Web and Mobile Development </h2>
                                    <p className='ms-3'>The user interface is where AI meets the user. We are actively looking for web and mobile developers who can design and implement user-friendly interfaces for AI applications. Your work will play a pivotal role in ensuring that small businesses can seamlessly harness AI's potential.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>AI Research and Innovation </h2>
                                    <p className='ms-3'>Our commitment to innovation drives us to explore the latest developments in AI. If you are an AI researcher, scientist, or innovator, AI Pathfinder is the ideal platform to push the boundaries of AI. Join our team to explore new frontiers in artificial intelligence and make pioneering contributions.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Community and Content Creation </h2>
                                    <p className='ms-3'>Our community is at the core of our mission. If you are passionate about fostering a vibrant community of AI enthusiasts, content creators, and learners, consider joining our team. Your efforts will help us share knowledge, engage in discussions, and empower others on their AI journey.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Project Management and Business Development </h2>
                                    <p className='ms-3'>Behind every successful tech project is effective project management and business development. If you have a talent for project management, business strategy, or partnerships, you can help AI Pathfinder grow and deliver AI solutions to small businesses.</p>
                                </div>
                            </div>
                            <div className="">
                                <button className='apply'>Apply Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section14">
                    <div className="container">
                        <p className='mb-3'>Join AI Pathfinder and embark on a journey of innovation, collaboration, and impact in the tech field. Explore our current career opportunities, and be part of a team that's transforming small businesses with the power of AI.
                            Open role
                        </p>
                        <span className=''>When you click on each area of impact it should take you to open roles under each section </span>
                        <div className="">
                            <div className="mt-5">
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Machine Learning and Data Science:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Machine Learning Engineer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: We are seeking a passionate Machine Learning Engineer to join our team. In this role, you will work on developing and deploying machine learning models, data analysis, and contributing to AI projects. Your expertise will help small businesses harness the power of AI for growth and success.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>Position: Data Scientist</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: Join our team as a Data Scientist and use your analytical skills to extract insights from data. Collaborate on AI projects that leverage data-driven decision-making and contribute to our mission of empowering small businesses with AI solutions.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Natural Language Processing (NLP) and Language Technologies:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: NLP Engineer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: We are looking for an NLP Engineer to work on language technologies and NLP applications. Your expertise will be crucial in creating solutions that enhance communication and engagement for small businesses.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>Position: Content Writer (NLP Focus)</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: Join our content team with a focus on NLP. Create engaging content, including articles, tutorials, and guides, to educate our community on NLP and language technologies.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                Computer Vision and Image Analysis:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Computer Vision Engineer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: We are seeking a Computer Vision Engineer to work on image analysis and computer vision projects. Your contributions will enable small businesses to extract valuable information from visual data.</li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <p></p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                Cloud Computing and Infrastructure:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Cloud Engineer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: We are looking for a Cloud Engineer to optimize and manage our cloud infrastructure. Your role will be pivotal in ensuring the scalability and reliability of our AI platforms.</li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <p></p>
                                                        <ul>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                Web and Mobile Development:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Web Developer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: Join our team as a Web Developer and help design and implement user-friendly interfaces for AI applications. Your work will be instrumental in ensuring that small businesses can access AI solutions seamlessly.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>Position: UI/UX Designer</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: Join our design team with a focus on creating user-friendly interfaces for AI applications related to computer vision. Your work will play a key role in ensuring that small businesses can seamlessly utilize AI solutions.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                AI Research and Innovation:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: AI Research Scientist</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: If you're an AI research enthusiast, we invite you to join us to push the boundaries of AI innovation. Contribute to cutting-edge research and create pioneering solutions.</li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <p></p>
                                                        <ul>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                Community and Content Creation:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Community Moderator</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: Become a Community Moderator and foster a vibrant AI community. Engage in discussions, support fellow members, and facilitate knowledge-sharing.</li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <p></p>
                                                        <ul>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                Project Management and Business Development:
                                            </button>
                                        </h2>
                                        <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Position: Project Manager</p>
                                                        <ul>
                                                            <li>Location: [Location]</li>
                                                            <li>Description: If you excel in project management and business development, consider joining us to drive our projects forward and expand our AI solutions.</li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <p></p>
                                                        <ul>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <MarqueeContainer />
                </div>
                <div className="">
                    <ContactUs />
                </div>
            </div>
        </div>
    )
}

export default Pathfinder