import React from 'react';
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from '@chakra-ui/react';
import { MainContainer } from '../../components';
import { accordions } from '../../config/data';
import { Link as routerLink } from 'react-router-dom';

const FAQ = () => {
  return (
    <Box pb="50px">
      <MainContainer>
        <Heading
          as="h1"
          fontFamily="Roboto condensed"
          lineHeight="90px"
          fontSize="30px"
          textAlign="center"
          fontWeight="500"
        >
          Frequently asked questions.
        </Heading>
        <Box w={{ base: '100%', md: '70%' }} mx="auto" mt="2rem">
          <Accordion allowToggle>
            {accordions.map((accordion) => (
              <AccordionItem key={accordion.id}>
                <h2 className='m-0'>
                  <AccordionButton>
                    <Box
                      as="span"
                      lineHeight="normal"
                      fontWeight="500"
                      flex="1"
                      padding="15px 0"
                      fontFamily="Roboto condensed"
                      color="#059669"
                      textAlign="left"
                    >
                      {accordion.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontFamily="Roboto Condensed">
                  {accordion.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          <Box mt="1.5rem" fontFamily='Roboto Condensed' textAlign="center" fontSize="16px">
            Still have questions? Check our{' '}
            <Link as={routerLink} color="#059669" to="/faqs">
              FAQs Page
            </Link>{' '}
            or{' '}
            <Link as={routerLink} color="#059669" to="/getintouch">
              Contact Us
            </Link>{' '}
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default FAQ;
