import React from 'react';
import { useRoutes } from 'react-router-dom';
import {
  LandingPage,
  GetInTouch,
  FaqsPage,
  CompanyPage,
  SecurityPage,
  PartnerPage,
  SolutionsPage,
  ContactPage,
  AboutUs,
  Pathfinder,
  Signup
} from '../pages';
import Judge from '../pages/Judge/Judge';
import Auth from '../pages/Judge/Auth';
import Apply from '../pages/Judge/Apply';

export const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
        path: '/signup',
        element: <Signup />
    },
    {
      path: '/about',
      element: <AboutUs />,
    },
    {
      path: '/company',
      element: <CompanyPage />,
    },
    {
      path: '/solutions',
      element: <SolutionsPage />,
    },
    {
      path: '/getintouch',
      element: <GetInTouch />,
    },
    {
      path: '/contact',
      element: <ContactPage />,
    },
    {
      path: '/security',
      element: <SecurityPage />,
    },
    {
      path: '/faqs',
      element: <FaqsPage />,
    },
    {
      path: '/partner',
      element: <PartnerPage />,
    },
    {
      path: '/judge',
      element: <Judge />,
    },
    {
      path: '/pathfinders',
      element: <Pathfinder />,
    },
    {
      path: '/judge/auth',
      element: <Auth />,
    },
    {
      path: '/judge/apply',
      element: <Apply />,
    },
    {
      path: '*',
      element: <LandingPage />,
    },
  ]);
};
