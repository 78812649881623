import React from 'react';
import LinkNav from '../../components/LinkNav';
import Img1 from '../../assets/images/faqs/istockphoto-1349998432-612x612.jpeg';
import ContactUs from '../../components/ContactUs';

function FaqsPage() {
  return (
    <div>
      <div className="faqs">
        <LinkNav
          FirstUrl="/company"
          First="Company"
          Dash="|"
          SecondUrl="/faqs"
          Second="FAQs"
        />
        <div className="bannerr1">
          <h2 className="animate__animated animate__bounce">
            FAQs About <span>AI Pathfinder</span>
          </h2>
        </div>
        <div className="margin50"></div>
        <div className="ssection1">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img src={Img1} alt="" />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="h2">
                  Frequently Asked Questions About AI Pathfinder
                </h2>
                <p>
                  Welcome to the AI Pathfinder FAQs page. Here, we address
                  common questions about our mission, services, and community.
                  If you don't find the answer you're looking for, please feel
                  free to reach out to our team for further assistance.
                </p>
              </div>
            </div>
            <div className="ssection2">
              <div className="container">
                <div className="mt-5">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse1"
                          aria-expanded="false"
                          aria-controls="flush-collapse1"
                        >
                          What is AI Pathfinder's mission?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse1"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            AI Pathfinder's mission is to democratize AI's
                            potential, enabling small businesses to flourish in
                            a dynamic digital landscape. We believe that AI
                            should be accessible to all, not just large
                            corporations, and we work to provide tailored AI
                            solutions that empower small businesses.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse2"
                          aria-expanded="false"
                          aria-controls="flush-collapse2"
                        >
                          How can I get involved with AI Pathfinder?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse2"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            You can get involved with AI Pathfinder in several
                            ways. You can become a Pathfinder by volunteering
                            with us and exploring our career opportunities, or
                            partner with us. Join our community, contribute to
                            our projects, and participate in discussions to
                            share knowledge.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse3"
                          aria-expanded="false"
                          aria-controls="flush-collapse3"
                        >
                          What types of resources does AI Pathfinder offer?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse3"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            AI Pathfinder offers a wide range of AI resources,
                            including guides, webinars, case studies, an AI
                            Solution Marketplace and a vibrant community forum.
                            These resources are designed to empower you on your
                            AI journey and help you stay informed about the
                            latest AI developments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse4"
                          aria-expanded="false"
                          aria-controls="flush-collapse4"
                        >
                          Who are the Pathfinders at AI Pathfinder?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse4"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            Our Pathfinders form a dynamic team of AI innovators
                            and technology pioneers, hailing from diverse
                            backgrounds. Many of them are actively driving
                            innovation at renowned tech giants such as Amazon,
                            Google, Apple, Microsoft, and others. Their
                            collective dedication propels our mission forward,
                            and they generously share their expertise with our
                            community.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse5"
                          aria-expanded="false"
                          aria-controls="flush-collapse5"
                        >
                          How does AI Pathfinder support small businesses?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse5"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            AI Pathfinder supports small businesses by crafting
                            tailored AI solutions that streamline operations,
                            elevate decision-making, and amplify customer
                            engagement. We work with small businesses to
                            implement AI strategies that help them thrive in the
                            digital era.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse6"
                          aria-expanded="false"
                          aria-controls="flush-collapse6"
                        >
                          What types of partnerships does AI Pathfinder offer?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse6"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            AI Pathfinder offers partnerships in various
                            categories, including technology, content creation,
                            community engagement, research, and financial
                            partnerships. Whether you're a tech organization,
                            content creator, community enthusiast, researcher,
                            or financial supporter, there are opportunities to
                            collaborate with us.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse7"
                          aria-expanded="false"
                          aria-controls="flush-collapse7"
                        >
                          Is AI Pathfinder a for-profit organization?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse7"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            No, AI Pathfinder is a nonprofit tech startup
                            dedicated to making AI accessible to small
                            businesses. Our mission is focused on impact and
                            democratizing AI, rather than profit generation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse8"
                          aria-expanded="false"
                          aria-controls="flush-collapse8"
                        >
                          How do I apply for a career opportunity at AI
                          Pathfinder?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse8"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            To apply for a career opportunity at AI Pathfinder,
                            please visit our Career page and follow the
                            application instructions provided for the specific
                            job opening that interests you.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse9"
                          aria-expanded="false"
                          aria-controls="flush-collapse9"
                        >
                          How can I contact AI Pathfinder for more information?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse9"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            You can contact AI Pathfinder by clicking the
                            "Contact Us" button on our website, where you can
                            fill out a contact form to reach our team. We're
                            always here to assist with your inquiries and
                            provide more information.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse10"
                          aria-expanded="false"
                          aria-controls="flush-collapse10"
                        >
                          How can I stay updated with AI Pathfinder's latest
                          news and developments?
                        </button>
                      </h2>
                      <div
                        id="flush-collapse10"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            To stay updated with AI Pathfinder's latest news and
                            developments, visit our "News and Updates" page,
                            where you can find informative content about AI, our
                            community, and our projects. You can also follow us
                            on social media for real-time updates.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin50"></div>
        <div className="ssection3">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h1>
                  Still Have <br /> Questions?
                </h1>
              </div>
              <div className="col-md-7">
                <p>
                  If you have more questions or need further information, please
                  don't hesitate to reach out to our team. We're here to assist
                  you and provide the support you need to navigate the world of
                  AI and AI Pathfinder.
                </p>
              </div>
            </div>
          </div>
        </div>
        <ContactUs />
      </div>
    </div>
  );
}

export default FaqsPage;
