import React from 'react'
import Banner from './Banner'
import Solutions from './Solutions'
import ContactUs from '../../components/ContactUs'

function SolutionsPage() {
  return (
    <div>
        <Banner />
        <Solutions />
        <ContactUs />
    </div>
  )
}

export default SolutionsPage