import React from 'react'
import LinkNav from '../../components/LinkNav'
import Img1 from '../../assets/images/security/pexels-cottonbro-studio-3205735.jpg'
import Img2 from '../../assets/images/security/pexels-pixabay-39584.jpg'
import Img3 from '../../assets/images/security/pexels-sora-shimazaki-5668869.jpg'
import { Link } from 'react-router-dom';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config'

function SecurityPage() {
    return (
        <div>
            <div className="security">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/security"
                    Second="Security"
                />
                <div className="bannerr1">
                    <h2 className='animate__animated animate__bounce'>Security at <span>AI Pathfinder</span></h2>
                    <p>Safeguarding Your Trust and Data</p>
                </div>
                <div className="margin50"></div>
                <div className="sectioon1">
                    <div className="container">
                        <center>
                            <div className="col-md-8">
                                <p className='path'>At AI Pathfinder, we prioritize the security of your data and the trust you place in us. We are committed to maintaining the highest standards of security to protect your information and ensure a safe and reliable experience while using our services.</p>
                            </div>
                        </center>
                        <div className="margin50"></div>
                        <div className="row">
                            <div className="col-md-5">
                                <img src={Img1} alt="" />
                            </div>
                            <div className="col-md-7">
                                <div className="text">
                                    <h1 className='mb-3'>Our Security Commitment</h1>
                                    <ul>
                                        <li>
                                            <h2>Data Protection: </h2>
                                            <p> We employ state-of-the-art encryption and access controls to protect the confidentiality and integrity of your data. Your information is secured and used exclusively for its intended purposes.</p>
                                        </li>
                                        <li>
                                            <h2>Secure Infrastructure:</h2>
                                            <p> AI Pathfinder's infrastructure is designed with security in mind. We regularly monitor and update our systems to defend against potential threats.</p>
                                        </li>
                                        <li>
                                            <h2>Privacy Compliance: </h2>
                                            <p> We are dedicated to compliance with relevant data protection laws and regulations. Your privacy rights are respected, and we strive to provide transparent information about data collection and usage.</p>
                                        </li>
                                    </ul>
                                    <h1 className='mb-3'>Our Security Commitment</h1>
                                    <ul>
                                        <li>
                                            <h2>Personal Data Control:  </h2>
                                            <p>You have the ability to manage and control your personal data, including the option to delete your account and data. </p>
                                        </li>
                                        <li>
                                            <h2>Safe User Interaction: </h2>
                                            <p>We moderate user-generated content and maintain a safe and respectful environment for discussions and knowledge-sharing. </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="margin50"></div>
                        <div className="role">
                            <div className="row">
                                <div className="col-md-7  pt-4 mb-3">
                                    <h2>Your Role in Security</h2>
                                    <p>Security is a shared responsibility. We encourage all users to be vigilant and follow best practices when it comes to data protection. Here are a few tips to enhance your security:</p>
                                    <ul>
                                        <li>Use strong, unique passwords for your account.</li>
                                        <li>Enable two-factor authentication for added security.</li>
                                        <li>Be cautious about sharing personal information in public discussions.</li>
                                        <li>Report any suspicious activity or security concerns to our team.</li>
                                    </ul>
                                </div>
                                <div className="col-md-5 mb-3">
                                    <img src={Img3} alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <img src={Img2} alt="" />
                                </div>
                                <div className="col-md-7 pt-4 mb-3">
                                    <h2>Reporting Security Concerns</h2>
                                    <p>At AI Pathfinder, we encourage a community-driven approach to security. If you encounter a potential security issue or have concerns regarding security practices, please report them to our security team. We take all reports seriously and work promptly to address and resolve any issues.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="tab">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2>Security Updates</h2>
                                    <p>We are committed to keeping our users informed about security updates and measures. You can find information about security practices, updates, and any relevant security incidents on this page.</p>
                                </div>
                                <div className="col-md-6">
                                    <h2>Contact Us</h2>
                                    <p>If you have any security-related questions or concerns, please reach out to our security team at [Security Contact Email].</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectioon2">
                    <div className="container-xxl">
                    <div className="row">
                        <div className="col-md-6 p-0">
                            <div className="first">
                                <div className="line1"></div>
                                <div className=" light">
                                    <div className="logo">
                                        <Link to="/">
                                            <img
                                                src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473808/home-image/Ai-LOGO_ly3x1e.png`}
                                                alt=""
                                                className=""
                                            />
                                        </Link>
                                    </div>
                                    <h2> AI Pathfinder</h2>
                                </div>
                                
                                <img src="" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="second">
                                <div className="line2"></div>
                                <h2>Stay Secure with AI Pathfinder</h2>
                                <div className="mb-4"></div>
                                <p>AI Pathfinder is dedicated to ensuring the security of our users' data and maintaining a safe and trustworthy environment for all. We appreciate your partnership in safeguarding our platform and your information.
                                </p>
                            </div>
                        </div>
                    </div>Ç
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecurityPage