
const Volunteer = [
    {
        header: 'Meaningful Impact:',
        body: 'Volunteering with AI Pathfinder allows you to make a tangible impact in the world of AI and technology. Your contributions directly support our mission to empower small businesses with AI solutions. Knowing that your efforts are helping small businesses thrive is immensely rewarding.'
    },
    {
        header: 'Learning and Growth:',
        body: 'AI is a rapidly evolving field, and as a Pathfinder, you`ll have the opportunity to learn and grow alongside experts in the industry. Gain hands-on experience, enhance your skills, and keep up with the latest AI trends through practical projects and collaborations.'
    },
    {
        header: 'Networking and Collaboration:',
        body: 'Our Pathfinders come from diverse backgrounds and regions. You`ll have the chance to connect and collaborate with professionals, enthusiasts, and learners from around the world. This network can lead to exciting opportunities and new friendships.'
    },
    {
        header: 'Access to AI Resources: ',
        body: 'Volunteers at AI Pathfinder enjoy access to a wide range of AI resources, including guides, webinars, case studies, and research materials. This access allows you to stay informed about AI developments and deepen your AI knowledge.'
    },  {
        header: 'Portfolio Building:',
        body: 'For those looking to build a portfolio or enhance their resumes, volunteering with AI Pathfinder provides excellent opportunities. You can work on real AI projects and showcase your contributions to potential employers or collaborators.'
    },
    {
        header: ' Community and Recognition: ',
        body: 'AI Pathfinder is not just an organization; it`s a thriving community. As a volunteer, you`ll have the chance to actively engage with fellow Pathfinders, participate in discussions, and share your expertise. Your contributions will be recognized and appreciated within our community.'
    },
    {
        header: ' Flexibility and Remote Work: ',
        body: 'Volunteering with AI Pathfinder offers flexibility in terms of time and location. Many tasks can be done remotely, allowing you to balance your volunteer work with other commitments.'
    },
    {
        header: 'Unique Volunteer Roles:',
        body: 'We offer a variety of volunteer roles, from content creation to community moderation, event coordination, and tech projects. Choose a role that aligns with your interests and expertise.'
    },
    {
        header: 'Commitment to Inclusivity: ',
        body: 'AI Pathfinder is committed to providing an inclusive and supportive environment for volunteers. We value diversity and strive to ensure that everyone feels welcome and respected.'
    },
    {
        header: ' Making a Difference:',
        body: ' Most importantly, by volunteering with AI Pathfinder, you are actively contributing to a mission that goes beyond technology. You are helping small businesses flourish and equipping them with AI tools that can transform their operations and decision-making.'
    }
]


export {Volunteer};