// import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { values } from '../../config/data';
// import { LazyImage, MainContainer } from '../../components';
// import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

// const Values = () => {
//   return (
//     <Box as="section" pb="3rem" id="values">
//       <MainContainer>
//         <Box display="flex" justifyContent="center">
//           <Heading
//             as="h2"
//             bgColor="#FAFAFA"
//             width="fit-content"
//             fontFamily="Trocchi"
//             fontSize="26px"
//             fontWeight="400"
//             lineHeight="60px"
//             px="10%"
//             mb="1.5rem"
//             data-aos-duration="1000"
//             data-aos-delay="200"
//             data-aos-easing="linear"
//           >
//             OUR VALUES
//           </Heading>
//         </Box>

//         <Grid
//           templateColumns={{
//             base: 'repeat(1, 1fr)',
//             md: 'repeat(2, 1fr)',
//           }}
//           gap={{ base: '38px', md: '30px' }}
//           mt="2rem"
//         >
//           {values.map((item) => {
//             if (item.id % 2 !== 0) {
//               return (
//                 <>
//                   <GridItem
//                     key={item.id}
//                     display="flex"
//                     fontFamily="Roboto Condensed"
//                     flexDirection={{ base: 'column-reverse', md: 'row' }}
//                     alignItems="center"
//                     boxShadow="0px 4px 4px 0px rgba(69, 158, 158, 0.50)"
//                     bgColor="#FAFAFA"
//                     gap="23px"
//                     p="18px"
//                     data-aos="fade-right"
//                     data-aos-delay="50"
//                     data-aos-duration="1000"
//                     data-aos-easing="linear"
//                   >
//                     <Box w={{ base: '100%', md: '60%' }} textAlign="center">
//                       <Heading as="h6" mb="1.5rem">
//                         {item.title}
//                       </Heading>

//                       <Text lineHeight="30px">{item.text}</Text>
//                     </Box>

//                     <Box>
//                       <LazyImage
//                         src={item.image}
//                         width="130px"
//                         height="115px"
//                         alt={item.title}
//                       />
//                     </Box>
//                   </GridItem>
//                 </>
//               );
//             } else {
//               return (
//                 <>
//                   <GridItem
//                     key={item.id}
//                     display="flex"
//                     fontFamily="Roboto Condensed"
//                     flexDirection={{ base: 'column', md: 'row' }}
//                     alignItems="center"
//                     boxShadow="0px 4px 4px 0px rgba(69, 158, 158, 0.50)"
//                     bgColor="#FAFAFA"
//                     gap="23px"
//                     p="18px"
//                     data-aos="fade-left"
//                     data-aos-delay="50"
//                     data-aos-duration="1000"
//                     data-aos-easing="linear"
//                   >
//                     <Box>
//                       <LazyImage
//                         src={item.image}
//                         width="130px"
//                         height="115px"
//                         alt={item.title}
//                       />
//                     </Box>

//                     <Box w={{ base: '100%', md: '60%' }} textAlign="center">
//                       <Heading as="h6" mb="1.5rem">
//                         {item.title}
//                       </Heading>

//                       <Text lineHeight="30px">{item.text}</Text>
//                     </Box>
//                   </GridItem>
//                 </>
//               );
//             }
//           })}
//         </Grid>

//         <Box display="flex" mt="2rem" justifyContent="center">
//           <Box
//             display="flex"
//             flexDirection="column"
//             alignItems="center"
//             fontFamily="Roboto Condensed"
//             justifyContent="center"
//             boxShadow="0px 4px 4px 0px rgba(69, 158, 158, 0.50)"
//             bgColor="#FAFAFA"
//             gap="23px"
//             p="18px"
//             w={{ base: '100%', md: '40%' }}
//             data-aos="fade-up"
//             data-aos-anchor-placement="top-center"
//           >
//             <Box>
//               <LazyImage
//                 src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697472955/company-image/integrity_yyc5hu.png`}
//                 width="130px"
//                 height="115px"
//                 alt="integrity"
//               />
//             </Box>

//             <Box w={{ base: '100%', md: '70%' }} textAlign="center">
//               <Heading as="h6" mb="1.5rem">
//                 Integrity
//               </Heading>

//               <Text lineHeight="30px">
//                 We operate with integrity and transparency in all our
//                 interactions, both within our team and with our users and
//                 partners.
//               </Text>
//             </Box>
//           </Box>
//         </Box>
//       </MainContainer>
//     </Box>
//   );
// };
function Values() {
  return (
    <div>
      <div className="margin50"></div>
      <div className="values">
        <div className="sect">
          <div className="container">
            <center>
              <h1 className='mb-5'>Our Values</h1>
            </center>
            <div className="row">
              {values.map((each, key) => (
                <div className="col-md-4 mb-3" key={key}>
                  <div className="cardd">
                    <img src={each.image} alt="" />
                    <div className="text">
                      <div className="flex">
                        <h2>{each.title}</h2>
                        <span className='animate__animated animate__fadeInDown animate__slower	3s animate__repeat-3	3	'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </div>
                      <p>{each.text}</p>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Values;
