import React from 'react'

function Header() {
  return (
    <div>
        <div className="Header">
            <div className="container">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <span>HOME</span>
                    </div>
                    <div className="col-md-3">
                        <span>MY ACCOUNT</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header