import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import vision from '../../assets/images/company-image/vision.jpeg';
import { HeadingTwo } from './Mission';
import { LazyImage, MainContainer } from '../../components';

const Vision = () => {
  return (
    <Box
      as="section"
      id="vision"
      data-aos="fade-up"
      data-aos-duration="3000"
      pb="60px"
    >
      <MainContainer>
        <Box
          display="flex"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          // alignItems="center"
          justifyContent="space-between"
          gap={{ base: '20px', md: '0' }}
        >
          <Box w={{ base: '100%', md: '30%' }} pt="1rem">
            <LazyImage
              src={vision}
              width="340px"
              style={{ marginRight: 'auto', marginLeft: 'auto' }}
              height="340px"
              alt="vision-img"
            />
          </Box>

          <Box textAlign="center" w={{ base: '100%', md: '55%' }}>
            <HeadingTwo text="Vision" marginBlockStart="0" marginBlockEnd="0" />

            <Text
              as="p"
              fontFamily="Roboto Condensed"
              fontSize="22px"
              lineHeight="40px"
            >
              Our vision is to usher in a future where every small business,
              regardless of size or industry, harnesses the transformative power
              of artificial intelligence to thrive and lead in an increasingly
              digital world. We envision a landscape where AI seamlessly
              integrates into daily operations, enhancing efficiency,
              productivity, and innovation across the board. Our commitment is
              to be the guiding light, the pioneer, and the trusted partner for
              small businesses on their journey to unlock AI's limitless
              possibilities, making the once complex, simple, and the
              unattainable, achievable.
            </Text>
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Vision;
