import React from 'react'
import Modal from 'react-modal';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '30px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000'
    },
};

function GetStartedModal() {
    // let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <button onClick={openModal} class="btn btnsDeepGreen ms" type="">Get Started
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="Getstartedmodal">
                    <h1>Get started by signing up below:</h1>
                    <div className="diff">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfjzL84dZYwlTVphl_l0JpELUtTNQA4aZmSdnRgUYiHwibBhA/viewform?usp=pp_url"> <button className='upper'>Sign up as Pathfinder</button></a>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSe81WeuOhh5_moSeA2OZoAGXt_Vyg_SnCGsDzia--6UFPQBDg/viewform?usp=pp_url"> <button className='down'>Sign up as User</button></a>
                    </div>
                </div>
               <center>
               <button onClick={closeModal} className='btnsDark mt-4'>Close</button>
               </center>
            </Modal>
        </div>
    );
}


export default GetStartedModal