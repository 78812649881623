import { CLOUDINARY_DOMAIN_ACCOUNT } from './constant';
import Img1 from '../assets/images/values/pexels-andrea-piacquadio-3823488.jpg'
import Img2 from '../assets/images/values/pexels-athena-1963622.jpg'
import Img3 from '../assets/images/values/pexels-cottonbro-studio-8721318.jpg'
import Img4 from '../assets/images/values/pexels-johannes-plenio-1136465.jpg'
import Img5 from '../assets/images/values/pexels-julia-m-cameron-4143800.jpg'
import Img6 from '../assets/images/values/pexels-katie-rainbow-🏳️_🌈-9714250.jpg'
import Img7 from '../assets/images/values/pexels-savvas-stavrinos-814544.jpg'
import adebusola_otun from '../assets/adebusola_otun.jpeg'
import Tesla from '../assets/images/Home/TESLA.png'
import Amazon from '../assets/images/Home/Group.png'

const team = [
  {
    id: 1,
    name: 'Omoyeni Ogundipe',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473952/home-image/omoyeni_ojhsj9.jpg`,
    role: 'Founder',
  },
  {
    id: 5,
    name: 'Adebusola Otun',
    image: adebusola_otun,
    role: 'Co-founder',
  },
  // {
  //   id: 2,
  //   name: 'Aishat Olatunji',
  //   image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473812/home-image/aishat_gdv9ua.jpg`,
  //   role: 'Data Scientist',
  // },
  {
    id: 3,
    name: 'Vivian Okeke',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473943/home-image/vivianca_adahht.jpg`,
    role: 'Brand Manager',
  },
  // {
  //   id: 4,
  //   name: 'Itunu Olufemi',
  //   image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473845/home-image/itunu_fefxrk.jpg`,
  //   role: 'SWE',
  // },
  // {

];

const testimonials = [
  {
    id: 1,
    name: 'Yemisi Emmanuella',
    role: 'Owner of Stanwick enterprise',
    text: "I was skeptical about tech at first, but thanks to AIPathfinder's program, I embraced online ordering and expanded my customer base. My store has never been busier!",
  },
  {
    id: 2,
    name: 'Ademidoyin',
    role: 'CEO Ademidoyin wigs',
    text: "AIPathfinder and their team helped us implement AI-driven analytics tools, and it revolutionized how we serve our clients. We're more data-driven than ever.",
  },
  {
    id: 3,
    name: 'Monsurat Agboola',
    role: 'Founder of 24 Eleven',
    text: 'I cannot thank AIPathfinder enough for guiding me through the world of e-commerce. My designs are now available to a global audience!',
  },
  {
    id: 4,
    name: 'Bradley Cooper',
    role: 'Managing Partner at C & C Venture',
    text: 'AIPathfinders fraud detection algorithm has been a lifesaver for our small online marketplace. It has helped us reduce losses drastically ',
  },
  {
    id: 5,
    name: 'Ad Opa',
    role: 'CEO of Ad Opa Stor',
    text: 'I often experience huge debt in  my business but since I started integrating AIPathfinder algorithm into my business, I have been able to save a lot  and my customers now trust me once again ',
  },
  {
    id: 6,
    name: 'James Kinf',
    role: 'Engineer',
    text: 'As a small credit union, we have found AIPathfinders fraud detection solution to be incredibly effective. It helps us catch suspicious transactions early, protecting our members accounts from fraudsters',
  }
];

const values = [
  {
    id: 1,
    title: 'Innovation',
    text: `We embrace innovation as the driving force behind AI Pathfinder, continually seeking creative solutions to empower small businesses through AI.`,
    image: `${Img4}`
  },
  {
    id: 2,
    title: 'Empowerment',
    text: `We are committed to empowering small businesses to harness the power of AI, enabling them to thrive and excel in a dynamic digital landscape.`,
    image: `${Img1}`
  },
  {
    id: 3,
    title: 'Education',
    text: `We believe in the transformative power of education. We are dedicated to providing accessible and comprehensive AI education and resources to our users.`,
    image: `${Img5}`
  },
  {
    id: 4,
    title: 'Community',
    text: `We foster a strong and supportive community of small business owners, AI enthusiasts, and experts who share knowledge, collaborate, and grow together.`,
    image: `${Img2}`
  },
  {
    id: 5,
    title: 'Accessibility',
    text: `We are passionate about making AI accessible to all, ensuring that no one is left behind in the AI revolution.`,
    image: `${Img3}`
  },
  {
    id: 6,
    title: 'Inclusivity',
    text: `We embrace diversity and inclusivity, recognizing that different perspectives and backgrounds drive innovation and creativity.`,
    image: `${Img6}`
  },
  {
    id: 7,
    title: 'Integrity',
    text: `We operate with integrity and transparency in all our
    interactions, both within our team and with our users and
    partners.`,
    image: `${Img7}`
  },
];

const offer = [
  {
    id: 1,
    title: 'Expertise in AI',
    text: `Our team includes highly skilled experts from top technology companies worldwide. These experts possess deep knowledge of AI technologies and their practical applications across industries. They come from a variety of tech giants like Amazon, Google, Apple, and Microsoft, and they are passionate pioneers in the field.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319567/what%20we%20offer%20pics/expert_in_ai_p7fn07.jpg`,
  },
  {
    id: 2,
    title: 'Tailored Solutions',
    text: `We offer crafted customized AI solutions to address the specific needs and challenges of each small business client.
    Our approach is to leverage our Pathfinder’s expertise to analyze client requirements, recommend tailored AI solutions, and provide hands-on support throughout implementation.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319567/what%20we%20offer%20pics/TALOIRED_SOLUTIONS_iac0h5.jpg`,
  },
  {
    id: 3,
    title: 'Learning resources',
    text: `At AI Pathfinder, we empower small business owners and employees with the knowledge and skills to effectively integrate AI into their operations.
    We Offer comprehensive AI workshops, training programs, and educational materials, ensuring accessibility and relevance to diverse audiences`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319565/what%20we%20offer%20pics/learning_resources_knqp6f.jpg`,
  },
  {
    id: 4,
    title: 'Community Engagement',
    text: `We foster collaboration, knowledge sharing, and networking among small business owners, AI enthusiasts, and our team.
    Our approach is to establish and maintain a vibrant online community and networking hub where participants can engage, learn, and exchange experiences and insights.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319565/what%20we%20offer%20pics/community_engagement_i2ewo2.jpg`,
  },
  {
    id: 5,
    title: 'Social Impact Initiatives',
    text: `We encourage small businesses to use AI for social and environmental impact, aligning with responsible AI practices.
    We collaborate with businesses on AI-driven projects that address pressing social and environmental challenges, promoting ethical AI use.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319566/what%20we%20offer%20pics/social_impact_yrcjz5.jpg`,
  },
  {
    id: 6,
    title: 'Research and Development Grants',
    text: `AI Pathfinder's grants stimulate innovation within the small business sector, fostering industry-specific advancements and competitiveness.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319567/what%20we%20offer%20pics/reserch_and_development_cp7bkn.jpg`,
  },
  {
    id: 7,
    title: 'Consultation Services',
    text: `We offer personalized AI consultation services, providing businesses with expert guidance, ensuring successful AI integration and ongoing support.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319566/what%20we%20offer%20pics/CONSULTATION_SERVICES_cvihsl.jpg`,
  },
  {
    id: 8,
    title: 'AI Solution Marketplace',
    text: `The AI solution marketplace offers a curated selection of AI tools and applications, simplifying the process of finding and implementing AI solutions for small businesses.`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697319566/what%20we%20offer%20pics/marketplace_ai_ickbxq.jpg`,
  },
];

const storyData = [
  {
    id: 1,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473902/home-image/coffee-lady_q6r49s.jpg`,
    title: 'Coffee-woman',
  },
  {
    id: 2,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473868/home-image/tailor_agqagi.jpg`,
    title: 'Fashion-designer',
  },
  {
    id: 3,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473821/home-image/bakery-man_xrkipa.jpg`,
    title: 'Cafe-man',
  },
  {
    id: 4,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473950/home-image/man-lady-with-laptop_jmd9cd.png`,
    title: 'man-with-lady-and-laptop',
  },
];

const blogData = [
  {
    id: 1,
    title: 'The ABCs of AI for Small Businesses',
    text: `Artificial Intelligence (AI) is no longer a 
    futuristic concept reserved for tech giants and science fiction. 
    It's a transformative force that's making its mark in the world 
    of small businesses, offering new opportunities for growth, 
    efficiency, and innovation. In this blog post, we'll break...`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473877/home-image/ai-blog_infkgu.jpg`,
  },
  {
    id: 2,
    title: 'Choosing the Right AI Solution',
    text: `Artificial Intelligence (AI) is no longer a futuristic 
    concept reserved for tech giants and science fiction. 
    It's a transformative force that's making its mark in 
    the world of small businesses, offering new opportunities 
    for growth, efficiency, 
    and innovation. In this blog post, we'll break...`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473843/home-image/human-blog_ooqfiu.png`,
  },
  {
    id: 3,
    title: 'AI in Marketing and Customer Engagement',
    text: `Artificial Intelligence (AI) is no longer a futuristic 
    concept reserved for tech giants and science fiction. 
    It's a transformative force that's making its mark in 
    the world of small businesses, offering new opportunities 
    for growth, efficiency, 
    and innovation. In this blog post, we'll break...`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473871/home-image/third-blog_l08o4r.png`,
  },
  {
    id: 4,
    title: 'AI and E-commerce',
    text: `Artificial Intelligence (AI) is no longer a futuristic 
    concept reserved for tech giants and science fiction. 
    It's a transformative force that's making its mark in 
    the world of small businesses, offering new opportunities 
    for growth, efficiency, 
    and innovation. In this blog post, we'll break...`,
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473836/home-image/fourth-blog_nu13wz.png`,
  },
];

const serviceData = [
  {
    id: 1,
    title: 'Innovate',
    text: 'At AI Pathfinder, we harness the power of Artificial Intelligence to create game-changing solutions for small businesses.',
  },
  {
    id: 2,
    title: 'Educate',
    text: 'We offer accessible, comprehensive and easy to understand AI learning resources and programs for small businesses.',
  },
  {
    id: 3,
    title: 'Empower',
    text: 'With a vast array of AI resources, tools and support, we help small businesses reach optimum scale and efficiency, helping them thrive in a digital age.',
  },
];

const joinUsData = [
  {
    id: 1,
    title: 'Support us',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473941/home-image/support_ehtqvn.svg`,
  },
  {
    id: 2,
    title: 'Become a partner',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473864/home-image/partner_tbfd2t.svg`,
  },
  {
    id: 3,
    title: 'Volunteer with us',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473875/home-image/volunteer_tzfvq6.svg`,
  },
  {
    id: 4,
    title: 'Newsletter',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473860/home-image/newsletter_cv8w0s.svg`,
  },
];

const networks = [
  {
    id: 1,
    title: 'Amazon',
    image: `${Amazon}`,
  },
  {
    id: 2,
    title: 'Goldman Sachs',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473838/home-image/Goldman-Sachs_ztfbag.png`,
  },
  {
    id: 3,
    title: 'Nissan',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473862/home-image/Nissan_ywvdu9.jpg`,
  },
  {
    id: 4,
    title: 'Apple',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473819/home-image/apple_y8zlld.png`,
  },
  {
    id: 5,
    title: 'Microsoft',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473855/home-image/microsoft_dgzgpl.jpg`,
  },
  {
    id: 6,
    title: 'American Express',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473817/home-image/american-express_s7v09c.png`,
  },
  {
    id: 7,
    title: 'Tesla',
    image: `${Tesla}`,
  },
  {
    id: 8,
    title: 'EY',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473830/home-image/ey_cxgdjy.png`,
  },
  {
    id: 9,
    title: 'PWC',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473847/home-image/pwc_t3vwxs.png`,
  },
  {
    id: 10,
    title: 'Google',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473840/home-image/google_xwfglh.jpg`,
  },
  {
    id: 11,
    title: 'HP',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473858/home-image/hp_ljyqsg.jpg`,
  },
  {
    id: 12,
    title: 'Meta',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473904/home-image/meta_kict24.png`,
  },
  {
    id: 13,
    title: 'Ford',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473834/home-image/ford_znpefo.png`,
  },
  {
    id: 14,
    title: 'Tiktok',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473851/home-image/tiktok_gkqo2m.png`,
  },
  {
    id: 15,
    title: 'Deloitte',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473823/home-image/deloitte_jbchck.png`,
  },
  {
    id: 16,
    title: 'IBM',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473911/home-image/IBM_p6wmjc.png`,
  },
  {
    id: 17,
    title: 'Dropbox',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473827/home-image/dropbox_kj2ead.png`,
  },
];

const networksOne = [
  {
    id: 1,
    title: 'Amazon',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473814/home-image/amazon_fdx0k0.png`,
  },
  {
    id: 2,
    title: 'Goldman Sachs',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473838/home-image/Goldman-Sachs_ztfbag.png`,
  },
  {
    id: 3,
    title: 'Nissan',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473862/home-image/Nissan_ywvdu9.jpg`,
  },
  {
    id: 4,
    title: 'Apple',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473819/home-image/apple_y8zlld.png`,
  },
  {
    id: 5,
    title: 'Microsoft',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473855/home-image/microsoft_dgzgpl.jpg`,
  },
  {
    id: 6,
    title: 'American Express',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473817/home-image/american-express_s7v09c.png`,
  },
  {
    id: 7,
    title: 'Tesla',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473849/home-image/tesla_wxofud.png`,
  },
  {
    id: 8,
    title: 'EY',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473830/home-image/ey_cxgdjy.png`,
  },
  {
    id: 9,
    title: 'PWC',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473847/home-image/pwc_t3vwxs.png`,
  },
];

const networksTwo = [
  {
    id: 1,
    title: 'Google',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473840/home-image/google_xwfglh.jpg`,
  },
  {
    id: 2,
    title: 'HP',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473858/home-image/hp_ljyqsg.jpg`,
  },
  {
    id: 3,
    title: 'Meta',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473904/home-image/meta_kict24.png`,
  },
  {
    id: 4,
    title: 'Ford',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473834/home-image/ford_znpefo.png`,
  },
  {
    id: 5,
    title: 'Tiktok',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473851/home-image/tiktok_gkqo2m.png`,
  },
  {
    id: 6,
    title: 'Deloitte',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473823/home-image/deloitte_jbchck.png`,
  },
  {
    id: 7,
    title: 'IBM',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473911/home-image/IBM_p6wmjc.png`,
  },
  {
    id: 8,
    title: 'Dropbox',
    image: `${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473827/home-image/dropbox_kj2ead.png`,
  },
];

const accordions = [
  {
    id: 1,
    question: 'What does AI Pathfinder specialize in?',
    answer: `At AI Pathfinder, we specialize in leveraging AI (Artificial Intelligence) innovation to empower and catalyze the success of small businesses.`,
  },
  {
    id: 2,
    question: 'How can AI Pathfinder benefit my small business?',
    answer: `AI Pathfinder can offer various benefits, 
    such as improved decision-making, 
    enhanced customer experiences, automation of repetitive tasks, and data-driven insights that can help you grow and compete more effectively.`,
  },
  {
    id: 3,
    question: 'What specific AI solutions does AI Pathfinder offer for small businesses?',
    answer: `With our expertise in AI, we offer a range of AI solutions, including predictive analytics, customer segmentation, personalized marketing, and automation tools tailored to the unique needs of small businesses.`,
  },
  {
    id: 4,
    question:
      'Is AI technology too complex or expensive for a small business to implement?',
    answer: `Not at all. We provide AI solutions designed to be accessible and cost-effective for small businesses, allowing you to harness the power of AI without breaking the bank.`,
  },
  {
    id: 5,
    question: 'How do I get started with your AI services?',
    answer: `To get started, you can reach out to our team for a consultation. We'll assess your specific needs and tailor a plan that aligns with your business goals. Check our Contact US section for more details.`,
  },
  {
    id: 6,
    question:
      'Do you provide training or support for small businesses adopting AI technology?',
    answer: `Yes, we offer training, onboarding support, and ongoing assistance to ensure you make the most of our AI solutions and can use them effectively.`,
  },
  {
    id: 7,
    question: 'What is the return on investment (ROI) of implementing AI in a small business?',
    answer: `The ROI of AI varies depending on factors like the type of business and the specific use case. We can work with you to measure and maximize the ROI of your AI investments.`
  },
  {
    id: 8,
    question: 'Is my business data safe when using your AI solutions?',
    answer: `Yes, we take data security seriously and implement robust measures to protect your business data. We comply with industry standards and regulations.`,
  },
];

export {
  team,
  testimonials,
  values,
  offer,
  serviceData,
  storyData,
  blogData,
  joinUsData,
  networks,
  networksOne,
  networksTwo,
  accordions,
};
