import React from 'react'
// import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/Home/Image (7).png'
import GetStartedModal from '../../components/GetStartedModal'

function Banner1() {
    return (
        <div>
            <div className="padding50"></div>
            <div className="Banner1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h1>Your Premier Destination for AI <span>Innovation</span> and <span>Expertise</span></h1>
                            <p><span>AI Pathfinder </span> leads the charge in AI innovation, providing exceptional experiences and unmatched expertise to empower small businesses in realizing their AI potential.</p>
                            <GetStartedModal />
                        </div>
                        <div className="col-md-6 mb-3">
                            <img src={Img1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner1