import React from 'react';
// import Header from './Header';
// import Footer from './Footer';
import Navbar from './Navbar';
import NewFooter from './NewFooter';


const Layout = (props) => {
  return (
    <>
      <Navbar />
      {props.children}

      {/* <Footer /> */}
      <NewFooter />
    </>
  );
};

export default Layout;
