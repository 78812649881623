import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { LazyImage, MainContainer } from '../../components';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

const Business = () => {
  return (
    <Box as="section" mt="2rem" data-aos="fade-up" data-aos-duration="4000">
      <MainContainer>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={{ base: '20px', md: '0' }}
        >
          <Box w={{ base: '100%', md: '40%' }}>
            <Text
              as="p"
              fontFamily="Roboto Condensed"
              fontSize="20px"
              lineHeight="45px"
            >
              We have a unique opportunity to empower small businesses by
              creating a holistic Small Business AI Empowerment Ecosystem. This
              ecosystem provides small businesses with the knowledge, tools, and
              support needed to seamlessly integrate artificial intelligence
              into their operations, enabling them to thrive and lead in an
              increasingly digital world. By offering AI integration workshops,
              an AI solution marketplace, consultation services, a community
              hub, and more, AIPathfinder aims to democratize AI's potential,
              making it accessible and transformative for small businesses
              across industries.
            </Text>
          </Box>

          <Box w={{ base: '100%', md: '55%' }}>
            <LazyImage
              width='100%'
              height='auto'
              src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697472967/company-image/bakery-owner_pzxgw8.jpg`}
              alt="bakery-owner"
            />
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Business;
