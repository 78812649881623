import React from 'react'
import Img1 from '../../assets/images/Home/Image.png'
import { Link } from 'react-router-dom'

function Why() {
    return (
        <div>
            <div className="Why">
                <div className="container">
                <div className="header">
                    <center>
                        <div className="col-md-10">
                            <h2>Why AI Pathfinder?</h2>
                            <p>AI Pathfinder is your trusted guide in the realm of artificial intelligence. We are a non-profit, dynamic and passionate team of AI experts, tech enthusiasts, and small business advocates committed to democratizing AI's potential.</p>
                        </div>
                    </center>
                </div>
                <div className="Tab">
                    <div className="row">
                        <div className="col-md-4 ps-3 pe-3 mb-3">
                            <div className="tab">
                                <h2>Innovate</h2>
                                <p>At AI Pathfinder, we harness the power of Artificial Intelligence to create game-changing solutions for small businesses.</p>
                                <Link to="solutions"><button>Learn more</button></Link>
                            </div>
                        </div>
                        <div className="col-md-4 ps-3 pe-3 mb-3">
                            <div className="tab">
                                <h2>Educate</h2>
                                <p>We offer accessible, comprehensive and easy to understand AI learning resources and programs for small businesses.</p>
                                <Link to="solutions"><button>Learn more</button></Link>
                            </div>
                        </div>
                        <div className="col-md-4 ps-3 pe-3 mb-3">
                            <div className="tab">
                                <h2>Empower</h2>
                                <p>With a vast array of AI resources, tools and support, we help small businesses reach optimum scale and efficiency, helping them thrive in a digital age.</p>
                                <Link to="solutions"><button>Learn more</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Market">
                    <center><img src={Img1} alt="" /></center>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Why