import React from 'react';
import Hero from './Hero';
import Mission from './Mission';
import Vision from './Vision';
import Values from './Values';
import Unlocking from './Unlocking';
import Business from './Business';
import Offer from './Offer';
import SectionBar from './SectionBar';
import ContactUs from '../../components/ContactUs';

const CompanyPage = () => {
  return (
    <>
      <Hero />
      <SectionBar />
      <Mission />
      <Vision />
      <Values />
      <Unlocking />
      <Business />
      <Offer />
      <ContactUs />
    </>
  );
};

export default CompanyPage;
