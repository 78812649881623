import React from 'react'
import Img1 from '../../assets/images/Home/Image (3).jpg'
import Img2 from '../../assets/images/Home/Image (4).png'
import Img3 from '../../assets/images/Home/Image (5).png'
import Img4 from '../../assets/images/Home/Image (6).png'


function VisitBlog() {
  return (
    <div>
       <div className="Visitblog">
       <div className="container">
            <h1>Visit Our Blog</h1>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="imgdiv">
                        <img src={Img1} alt="" />
                        <span>Marketing</span>
                    </div>
                    <div className="mt-3">
                        <h3>The ABCs of AI for Small Businesses</h3>
                        <p>Artificial Intelligence (AI) is no longer a futuristic concept reserved for tech giants and science fiction. It's a transformative force that's making its mark in the world of small businesses, offering new opportunities for growth, efficiency, and innovation. In this blog post, we'll break...</p>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="imgdiv">
                        <img src={Img2} alt="" />
                        <span>Analytics</span>
                    </div>
                    <div className="mt-3">
                        <h3>AI in Marketing and Customer Service</h3>
                        <p>Artificial Intelligence (AI) is no longer a futuristic concept reserved for tech giants and science fiction. It's a transformative force that's making its mark in the world of small businesses, offering new opportunities for growth, efficiency, and innovation. In this blog post, we'll break...</p>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6 mb-3">
                    <div className="imgdiv">
                        <img src={Img3} alt="" />
                        <span>Marketing</span>
                    </div>
                    <div className="mt-3">
                        <h3>Choosing the Right AI Solution</h3>
                        <p>Artificial Intelligence (AI) is no longer a futuristic concept reserved for tech giants and science fiction. It's a transformative force that's making its mark in the world of small businesses, offering new opportunities for growth, efficiency, and innovation. In this blog post, we'll break...</p>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="imgdiv">
                        <img src={Img4} alt="" />
                        <span>Analytics</span>
                    </div>
                    <div className="mt-3">
                        <h3>AI and E-commerce</h3>
                        <p>Artificial Intelligence (AI) is no longer a futuristic concept reserved for tech giants and science fiction. It's a transformative force that's making its mark in the world of small businesses, offering new opportunities for growth, efficiency, and innovation. In this blog post, we'll break...</p>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default VisitBlog