import React from 'react'
import { Link } from 'react-router-dom'

function InviteToNetwork() {
    return (
        <div>
            <div className="InviteToNetwork ">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 ">
                            <div className="left">
                                <h3 className='green'><b>APPLY TO GET INVITED TO OUR NETWORK</b></h3>
                                <a href="https://forms.gle/tvqDsH737QJ3rAze8" target='_blank'>
                                    <button className='upper'>Apply to get invited</button>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="right">
                                <p>AI Pathfinder is a leading AI platform in the United States, offering an extensive network of professionals and opportunities. Our network is exclusively invitation-based, ensuring membership is granted solely on merit through submitted applications.</p>
                            </div>
                        </div>
                    </div>
                    <div className="borderTopWhite"></div>
                    <div className="row ">
                        <div className="col-md-6">
                            <div className="left ">
                                <h3 className='green'><b>JUDGING</b></h3>
                                <p className='green'><b> REGISTER NOW TO VOLUNTEER AS A 2024 JUDGE</b></p>
                                <p className='green'>Submit an application to be the Judge</p>
                               <Link to="/judge/auth"> <button className='upper'>Apply to be a judge at AI Pathfinder</button></Link>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="right borderTopGreen">
                                <p>Are you passionate about evaluating nominations from successful companies? Join our esteemed panel of judges to review and score entries online. You’ll have the flexibility to assess and rate as many nominations as you prefer at your convenience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InviteToNetwork