import React from "react"

const Dropdown = (
    <div className="dropdown">
        <button className="btn " type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
        </button>
        <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Edit</a></li>
            <li><a className="dropdown-item" href="#">Copy</a></li>
            <li><a className="dropdown-item" href="#">Nomination</a></li>
            <li><a className="dropdown-item" href="#">Print</a></li>
        </ul>
    </div>
)


export const completedJudginData = [

]


export const inProgressJudginData = [

]

export const allSubmissions = [
    {
        program: "10th Annual 2024 Batch A",
        round: "Main",
        nomination: "33282",
        category: "Product Development",
        title: "Innovative Tech Product",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "10th Annual 2024 Batch A",
        round: "Main",
        nomination: "33282",
        category: "Product Development",
        title: "Innovative Tech Product",
        status: "Complete",
        action: Dropdown
    },
    // Sample data for AI startups
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10001",
        category: "AI Integration",
        title: "AI-Powered Customer Support",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10002",
        category: "AI Integration",
        title: "AI-Driven Marketing Analytics",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10003",
        category: "AI Integration",
        title: "AI-Based Inventory Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10004",
        category: "AI Integration",
        title: "AI-Enhanced Sales Forecasting",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10005",
        category: "AI Integration",
        title: "AI-Powered Financial Analysis",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10006",
        category: "AI Integration",
        title: "AI-Driven Customer Insights",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10007",
        category: "AI Integration",
        title: "AI-Based Fraud Detection",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10008",
        category: "AI Integration",
        title: "AI-Enhanced Product Recommendations",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10009",
        category: "AI Integration",
        title: "AI-Powered HR Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024  Batch B",
        round: "Initial",
        nomination: "10010",
        category: "AI Integration",
        title: "AI-Driven Supply Chain Optimization",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10011",
        category: "AI Integration",
        title: "AI-Powered Chatbots for E-commerce",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10012",
        category: "AI Integration",
        title: "AI-Driven Social Media Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10013",
        category: "AI Integration",
        title: "AI-Based Customer Sentiment Analysis",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10014",
        category: "AI Integration",
        title: "AI-Enhanced Email Marketing",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10015",
        category: "AI Integration",
        title: "AI-Powered Lead Generation",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10016",
        category: "AI Integration",
        title: "AI-Driven Content Creation",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10017",
        category: "AI Integration",
        title: "AI-Based Competitive Analysis",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10018",
        category: "AI Integration",
        title: "AI-Enhanced Customer Feedback Analysis",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10019",
        category: "AI Integration",
        title: "AI-Powered Virtual Assistants",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10020",
        category: "AI Integration",
        title: "AI-Driven Personalization Engines",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10021",
        category: "AI Integration",
        title: "AI-Based Predictive Maintenance",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10022",
        category: "AI Integration",
        title: "AI-Enhanced Quality Control",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10023",
        category: "AI Integration",
        title: "AI-Powered Market Research",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10024",
        category: "AI Integration",
        title: "AI-Driven Pricing Optimization",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch C",
        round: "Initial",
        nomination: "10025",
        category: "AI Integration",
        title: "AI-Based Demand Forecasting",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10026",
        category: "AI Integration",
        title: "AI-Enhanced Customer Journey Mapping",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10027",
        category: "AI Integration",
        title: "AI-Powered Business Intelligence",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10028",
        category: "AI Integration",
        title: "AI-Driven Workflow Automation",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10029",
        category: "AI Integration",
        title: "AI-Based Document Processing",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10030",
        category: "AI Integration",
        title: "AI-Enhanced Cybersecurity",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10031",
        category: "AI Integration",
        title: "AI-Powered Risk Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10032",
        category: "AI Integration",
        title: "AI-Driven Talent Acquisition",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10033",
        category: "AI Integration",
        title: "AI-Based Employee Engagement",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch D",
        round: "Initial",
        nomination: "10034",
        category: "AI Integration",
        title: "AI-Enhanced Training Programs",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch D",
        round: "Initial",
        nomination: "10035",
        category: "AI Integration",
        title: "AI-Powered Performance Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch F",
        round: "Initial",
        nomination: "10036",
        category: "AI Integration",
        title: "AI-Driven Employee Retention",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10037",
        category: "AI Integration",
        title: "AI-Based Health Monitoring",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch B",
        round: "Initial",
        nomination: "10038",
        category: "AI Integration",
        title: "AI-Enhanced Fitness Programs",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch D",
        round: "Initial",
        nomination: "10039",
        category: "AI Integration",
        title: "AI-Powered Nutrition Planning",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch D",
        round: "Initial",
        nomination: "10040",
        category: "AI Integration",
        title: "AI-Driven Mental Health Support",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10041",
        category: "AI Integration",
        title: "AI-Based Personalized Learning",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10042",
        category: "AI Integration",
        title: "AI-Enhanced Educational Content",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch C",
        round: "Initial",
        nomination: "10043",
        category: "AI Integration",
        title: "AI-Powered Student Assessment",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10044",
        category: "AI Integration",
        title: "AI-Driven Classroom Management",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch C",
        round: "Initial",
        nomination: "10045",
        category: "AI Integration",
        title: "AI-Based Tutoring Systems",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch C",
        round: "Initial",
        nomination: "10046",
        category: "AI Integration",
        title: "AI-Enhanced Language Learning",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10047",
        category: "AI Integration",
        title: "AI-Powered Exam Preparation",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch A",
        round: "Initial",
        nomination: "10048",
        category: "AI Integration",
        title: "AI-Driven Career Counseling",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch C",
        round: "Initial",
        nomination: "10049",
        category: "AI Integration",
        title: "AI-Based Skill Development",
        status: "Complete",
        action: Dropdown
    },
    {
        program: "AI Pathfinder Awards 2024 Batch D",
        round: "Initial",
        nomination: "10050",
        category: "AI Integration",
        title: "AI-Enhanced Customer Retention",
        status: "Complete",
        action: Dropdown
    }
]