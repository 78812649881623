import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyImage = ({ src, width, height, alt, style }) => {
  return (
    <LazyLoadImage
      style={{ maxHeight: height, minHeight: height, ...style }}
      src={src}
      width={width}
      height={height}
      alt={alt}
    //   effect='blur'
    />
  );
};


export default LazyImage;

