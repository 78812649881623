import React from 'react'
import Img1 from '../../assets/images/Home/Image (1).png'
import Img2 from '../../assets/images/Home/Image (2).png'
// import { Link } from 'react-router-dom'

function Business() {
    return (
        <div>
            <div className="HomeBusiness">
                <div className="container">
                    <center>
                        <div className="col-md-7">
                            <p className='quote'><span>“ </span> Business <b>success</b> is at the heart of everything we do <span> ”</span></p>
                        </div>
                    </center>
                    <div className="row mt-5">
                        <div className="col-md-6 mb-3">
                            <div className="pt-4">
                                <h2>Our breadth of <br /> services</h2>
                                <p>We bring the full depth and breadth of AI expertise, capabilities and technologies to every client.</p>
                                <a href="solutions"><button>Learn more</button></a>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <img src={Img1} alt="" />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6 mb-3">
                            <img src={Img2} alt="" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="pt-4 ms">
                                <h2>Industry <br /> Impact</h2>
                                <p>Our unrivaled expertise and real-world experience ensure that clients stay at the forefront of their industries, no matter what challenges come their way.</p>
                                <a href="solutions"><button>Learn more</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Business