import React, { useState } from 'react'
import Header from './Components/Header'
import SideBar from './Components/SideBar'
import { allSubmissions } from './judgingData'

import shuffleArray from '../../utils/shuffle'

console.log("allSubmissions", allSubmissions);

const Dropdown = (
    <div className="dropdown">
        <button className="btn " type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
        </button>
        <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Edit</a></li>
            <li><a className="dropdown-item" href="#">Copy</a></li>
            <li><a className="dropdown-item" href="#">Nomination</a></li>
            <li><a className="dropdown-item" href="#">Print</a></li>
        </ul>
    </div>
)


const Application = (<>
    <h2>Application</h2>
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingInput" placeholder="John" />
        <label for="floatingInput">First Name</label>
    </div>
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingPassword" placeholder="Doe" />
        <label for="floatingPassword">Last Name</label>
    </div>
    <small>Please do not type everything in uppercase. <b>Correct:</b> John <b>Not correct:</b> JOHN DOE</small>
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingInput" placeholder="John Doe" />
        <label for="floatingInput">First Name</label>
    </div>
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingPassword" placeholder="Marketer" />
        <label for="floatingPassword">Job Title</label>
    </div>
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingInput" placeholder="" />
        <label for="floatingInput">Your Linkedln profile link (recommended)</label>
    </div>
    <div class="form-floating">
        <input type="text" class="form-control" id="floatingPassword" placeholder="Password" />
        <label for="floatingPassword">Work Email Address (must match with your work domain. No personal emails please)</label>
    </div>
</>)

const AllSubmission = (
    <>
        <h2 className=''>All Submissions</h2>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Program</th>
                    <th scope="col">Round</th>
                    <th scope="col">Nomination#</th>
                    <th scope="col">Category</th>
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                {
                    shuffleArray(allSubmissions).slice(0, 15).map((submission) => {
                        return (
                            <tr>
                                <td>{submission.program}</td>
                                <td>{submission.round}</td>
                                <td>{submission.nomination}</td>
                                <td>{submission.category}</td>
                                <td>{submission.title}</td>
                                <td>{submission.status}</td>
                                <td>
                                    {submission.action}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
        <span>Showing 1 to 1 entries</span> <br /><br />
        <span>This page shows activity from last 120 days. <a href="/judge/apply">show full history</a></span>
    </>
)

const JudgingAssignment = (
    <>
        <h2 className=''>Select Entry Round</h2>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Program</th>
                    <th scope="col">Round</th>
                    <th scope="col">Nomination#</th>
                    <th scope="col">Category</th>
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
            {
                    shuffleArray(allSubmissions).slice(0, 15).map((submission) => {
                        return (
                            <tr>
                                <td>{submission.program}</td>
                                <td>{submission.round}</td>
                                <td>{submission.nomination}</td>
                                <td>{submission.category}</td>
                                <td>{submission.title}</td>
                                <td>{submission.status}</td>
                                <td>
                                    {submission.action}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
        {/* <p className='p-3' style={{ background: "#efefef" }}>No submissions available for judging. if you believe this is an error, ask the admin to make sure the judging period is open and that you are assigned to submissions.</p> */}
        <div className="d-flex align-items-center">
            <span>Display</span>
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle mx-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    100 (recommend)
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">100 (recommend)</a></li>
                </ul>
            </div>
            <span>records</span>
        </div> <br />
        <span>Showing 0 to 0 0f 0 entries</span>
        .
    </>
)

function Apply() {



    const [details, setDetails] = useState(Application)

    const setApplication = () => {
        setDetails(Application)
    }
    const setAll = () => {
        setDetails(AllSubmission)
    }
    const setJudging = () => {
        setDetails(JudgingAssignment)
    }

    return (
        <div>
            <div className="container">
                <div className="padding50"></div>
                <div className=" Apply">
                    <Header />
                    <div className="row">
                        <div className="col-md-3">
                            <SideBar setApplication={setApplication} setAll={setAll} setJudging={setJudging} />
                        </div>
                        <div className="col-md-9">
                            {details}
                        </div>
                    </div>
                    <div className="margin50"></div>
                </div>
            </div>
        </div>
    )
}

export default Apply