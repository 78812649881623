import React from 'react'
import Img1 from '../../assets/images/contact/pexels-michelangelo-buonarroti-8728382.jpg'

function ContactPage() {
    return (
      <>
        <div className="contact">
          <div className="padding50"> </div>
          <div className="container-xxl">
          <div className="row">
            <div className="col-md-6 p-0">
              <img
                src={Img1}
                alt=""
                className="Contactimage"
              />
            </div>
            <div className="col-md-6 p-5">
              <h2>Contact Us</h2>
              <div className="mb-4"></div>
              <form action="">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingName"
                    placeholder="Name"
                  />
                  <label for="floatingName">Name</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Email address</label>
                </div>
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingMessage"
                    placeholder="Message"
                  />
                  <label for="floatingMessage">Message</label>
                </div>
                <button>Submit</button>
              </form>
            </div>
          </div>
          </div>
        </div>
      </>
    );
}

export default ContactPage