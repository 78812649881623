import { Box, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { MainContainer } from '../../components';

const ButtonLink = ({ href, text }) => (
  <Link
    href={href}
    bgColor="green.100"
    color="black.50"
    borderRadius="30px"
    w="300px"
    p="1rem 2rem"
    fontSize='20px'
    fontWeight='500'
    textAlign='center'
    _hover={{
      textDecoration: 'none',
      opacity: 0.7
    }}
  >
    {text}
  </Link>
);

const Signup = () => {
  return (
    <Box pt={{ base: '5rem', md: '9rem' }} pb="2rem">
      <MainContainer>
        <Box>
          <Box
            w={{ base: '100%', md: '50%' }}
            borderRadius="15px"
            px="0.8rem"
            py="2rem"
            fontFamily="Roboto Condensed"
            bgColor="gray.50"
            boxShadow="0px 4px 50px 0px rgba(85, 175, 175, 0.46)"
            mx="auto"
          >
            <Heading
              as="h2"
              my="0.8rem"
              color="green.100"
              textAlign="center"
              fontFamily="Trocchi"
            >
              Sign Up
            </Heading>
            <Text as="p" textAlign="center" my="1.5rem">
              Welcome to AI Pathfinder, pick a sign up mode that suits you
              from our options or
              <Link color="green.100" href="https://discord.gg/Pbb5ak3F" isExternal>
              {' '} Join our discord <br />
              </Link>
            </Text>

            <Box
              my="2.5rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="30px"
            >
              <ButtonLink
                text="Sign up as a User"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe81WeuOhh5_moSeA2OZoAGXt_Vyg_SnCGsDzia--6UFPQBDg/viewform?usp=pp_url"
              />
              <Box>OR</Box>
              <ButtonLink
                text="Sign up as a Pathfinder"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfjzL84dZYwlTVphl_l0JpELUtTNQA4aZmSdnRgUYiHwibBhA/viewform?usp=pp_url"
              />

            </Box>

          </Box>

        </Box>


      </MainContainer>
    </Box>
  );
};

export default Signup;
