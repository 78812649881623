import React from 'react'
import Img3 from '../assets/images/connected/IMG_2262.JPG'
function StayConected() {
    return (
        <>
            <div className="connected">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 p-3">
                            <h2>Stay <br /> Connected</h2>
                            <p>Join our community, follow our latest updates, and explore the boundless opportunities of AI with us. Together, we will navigate the dynamic digital landscape, unlock AI's potential, and redefine the future of small businesses.</p>
                        </div>
                        <div className="col-md-3">
                            <img src={Img3} alt="" width="80%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StayConected