import React from 'react';
import FAQ from './FAQ';
import MarqueeContainer from './Marquee';
import Banner1 from './Banner1';
import Scale from './Scale';
import Why from './Why';
import Business from './Business';
import Carousel from './Carousel';
import VisitBlog from './VisitBlog';
import InviteToNetwork from './InviteToNetwork';


const LandingPage = () => {
  return (
    <>
      <Banner1 />
      <Scale />
      <Why />
      <Business />
      <Carousel />
      <VisitBlog />
      <InviteToNetwork />
      <MarqueeContainer />
      <FAQ />
    </>
  );
};

export default LandingPage;
