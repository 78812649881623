import React from 'react'
import { Link } from 'react-router-dom'

function Auth() {
    return (
        <div>
            <div className="padding50"></div>
            <div className="container">
                <div className="padding50"></div>
                <div className="JudgeAuth">
                    <center>
                        <div className="h2">LOGIN</div>
                        <div className="col-md-5">
                            <div className="login p-4">
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="floatingInput" value="USER" />
                                    <label for="floatingInput">Email address</label>
                                </div>
                                <div class="form-floating  mb-3">
                                    <input type="password" class="form-control" id="floatingPassword" value="12345678" />
                                    <label for="floatingPassword">Password</label>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="gridCheck" />
                                        <label class="form-check-label ms-2" for="gridCheck">
                                            remember me
                                        </label>
                                    </div>
                                </div>
                                <Link to="/judge/apply">
                                    <div class="col-12">
                                        <button type="submit" class="btn btnsDeepGreen w-100">Login in</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default Auth