import React from 'react';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

function Solutions() {
  return (
    <>
      <div className="">
        <div className="container">
          <div className="margin50"></div>
          <div className="section1">
            <div className="row">
              <div className="col-md-5 mb-3">
                <div className="line1"></div>
                <h2>AI Solutions for Small Businesses</h2>
              </div>
              <div className="col-md-7 mb-3">
                <p>
                  At AI Pathfinder, we understand that small businesses are the
                  backbone of our economy, and we're here to empower you with
                  the transformative potential of artificial intelligence. Our
                  tailored AI solutions are designed to streamline operations,
                  elevate decision-making, and amplify customer engagement, all
                  with the goal of helping small businesses flourish in the
                  dynamic digital landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="margin50"></div>
        <div className="section2">
          <div className="container">
            <center>
              <h2>How AI Can Benefit Your Small Business</h2>
            </center>
            <div className="mb-5"></div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <img
                  src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473113/solutions/pexels-kindel-media-8566534_dkgup0.jpg`}
                  alt=""
                />
                <div className="text">
                  <h2>Enhanced Efficiency:</h2>
                  <div className="mb-3"></div>
                  <p>
                    {' '}
                    AI-powered automation can significantly improve your
                    business processes, reducing manual effort and minimizing
                    errors. From automating routine tasks to managing inventory,
                    AI optimizes efficiency across your operations.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <img
                  src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473120/solutions/pexels-michelangelo-buonarroti-8728388_syevhj.jpg`}
                  alt=""
                />
                <div className="text">
                  <h2>Data-Driven Insights: </h2>
                  <div className="mb-3"></div>
                  <p>
                    {' '}
                    Harness the power of AI to gain valuable insights from your
                    data. Our solutions provide you with data analysis and
                    predictive capabilities, enabling you to make informed
                    decisions and drive your business forward.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <img
                  src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473112/solutions/pexels-google-deepmind-17485678_y5hagl.jpg`}
                  alt=""
                />
                <div className="text">
                  <h2>Personalized Customer Engagement: </h2>
                  <div className="mb-3"></div>
                  <p>
                    {' '}
                    With AI, you can deliver personalized experiences to your
                    customers. Tailor your marketing, recommendations, and
                    customer support to individual preferences, enhancing
                    customer satisfaction and loyalty.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <img
                  src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473109/solutions/pexels-tara-winstead-8386434_gklz6y.jpg`}
                  alt=""
                />
                <div className="text">
                  <h2>Cost Savings: </h2>
                  <div className="mb-3"></div>
                  <p>
                    {' '}
                    AI can help you cut costs by optimizing resource allocation,
                    identifying areas for efficiency improvement, and reducing
                    the risk of human error. It's a cost-effective way to boost
                    your bottom line.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section3">
          <div className="head">
            <center>
              <h2>AI Solutions for your small business</h2>
            </center>
          </div>
          <div className="mb-5"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        AI-Driven Customer Personalization
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Small businesses are increasingly leveraging AI to
                        provide personalized customer experiences. AI algorithms
                        analyze customer data to offer tailored product
                        recommendations, content, and marketing messages,
                        enhancing customer engagement and loyalty.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        AI-Powered Chatbots and Virtual Assistants
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {' '}
                        Chatbots and virtual assistants are becoming ubiquitous
                        in small businesses' customer service operations.
                        AI-driven chatbots handle routine inquiries, freeing up
                        human staff for more complex tasks, and providing 24/7
                        support.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Predictive Analytics for Inventory Management
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        AI-powered predictive analytics help small retailers and
                        e-commerce businesses optimize inventory levels. By
                        forecasting demand and identifying trends, businesses
                        can reduce carrying costs and prevent stockouts.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        AI-Enhanced Cybersecurity
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Small businesses are increasingly vulnerable to cyber
                        threats. AI-driven cybersecurity solutions can detect
                        and respond to threats in real-time, protecting
                        sensitive data and maintaining customer trust.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Natural Language Processing (NLP) for Content Creation
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {' '}
                        AI-driven NLP tools assist small businesses in
                        generating high-quality content for marketing, social
                        media, and customer communications. These tools can
                        analyze audience preferences and craft compelling
                        content.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        AI in Financial Management
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Small businesses are adopting AI-powered financial tools
                        for tasks such as expense tracking, invoicing, and
                        financial forecasting. These solutions improve accuracy,
                        reduce manual work, and provide valuable insights.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven"
                      >
                        Remote Work Optimization
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        AI is helping small businesses optimize remote work
                        operations. AI-driven project management tools, virtual
                        collaboration platforms, and employee monitoring
                        solutions enhance productivity and teamwork in
                        distributed work environments.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseEight"
                      >
                        AI in Healthcare for Small Clinics
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {' '}
                        Small healthcare providers are using AI for patient data
                        analysis, appointment scheduling, and diagnosis support.
                        AI-driven healthcare solutions improve efficiency and
                        patient care.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine"
                        aria-expanded="false"
                        aria-controls="flush-collapseNine"
                      >
                        AI-Powered Marketing Automation
                      </button>
                    </h2>
                    <div
                      id="flush-collapseNine"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        AI-based marketing automation platforms are enabling
                        small businesses to segment their customer base, create
                        targeted campaigns, and measure marketing ROI more
                        effectively.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTen"
                        aria-expanded="false"
                        aria-controls="flush-collapseTen"
                      >
                        Sustainability and AI
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTen"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {' '}
                        Small businesses are integrating AI into sustainability
                        efforts. AI helps monitor energy consumption, reduce
                        waste, and optimize supply chains, aligning with
                        environmentally responsible practices.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEleven"
                        aria-expanded="false"
                        aria-controls="flush-collapseEleven"
                      >
                        AI for Language Translation
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEleven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {' '}
                        Small businesses expanding into global markets benefit
                        from AI-driven language translation tools. These
                        solutions can help enhance communication with
                        international customers and partners.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwelve"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwelve"
                      >
                        AI in Education and Training:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwelve"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Small businesses should invest in AI-driven training
                        programs to upskill employees. Personalized learning
                        paths and AI-driven assessments improve the efficiency
                        and effectiveness of training initiatives.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section4">
          <div className="container-xxl">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="first">
                  <div className="line1"></div>
                  <h2>
                    AI Pathfinder - Navigating Success Through AI Innovation.
                  </h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="second">
                  <div className="line2"></div>
                  <h2>Why Choose AI Pathfinder?</h2>
                  <div className="mb-4"></div>
                  <p>
                    At AI Pathfinder, we're dedicated to democratizing AI's
                    potential for small businesses. Our team of AI and tech
                    experts, drawn from top technology companies worldwide, brings
                    deep understanding and practical experience in the field of
                    artificial intelligence. We're passionate about helping small
                    businesses succeed and thrive in the digital era. Ready to
                    Supercharge Your Small Business with AI? Don't miss out on the
                    opportunities AI can offer your small business. Contact us
                    today to discuss how our tailored AI solutions can help you
                    optimize your operations, make data-driven decisions, and
                    engage customers like never before.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solutions;
