import React from 'react'
// import Img1 from '../../assets/images/solutions/arrow-gif.gif'
import $ from 'jquery';
import { CLOUDINARY_DOMAIN_ACCOUNT } from '../../config';

function Banner() {

    const Click = () => {
        var $target = $('.p.active').next('.p');
        if ($target.length === 0)
            $target = $('.p:first');

        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 'slow');

        $('.active').removeClass('active');
        $target.addClass('active');
    }

    return (
      <>
        <div className="padding50"></div>
        <div className="">
          <div className="banner1 p active">
            <div className="text">
              <div className="line1"></div>
              <h2>
                Illuminating Tech and AI Horizons for Business Excellence.
              </h2>
            </div>
            <div className="arrow">
              <img
                src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473101/solutions/arrow-gif_emxppg.gif`}
                alt=""
                onClick={Click}
              />
            </div>
          </div>
          <div className="banner2 p">
            <div className="text">
              <div className="line1"></div>
              <h2>Helping you use AI responsibly to do what matters.</h2>
            </div>
            <div className="arrow">
              <img
                src={`${CLOUDINARY_DOMAIN_ACCOUNT}/v1697473101/solutions/arrow-gif_emxppg.gif`}
                alt=""
                onClick={Click}
              />
            </div>
          </div>
          <div className="banner3 p">
            <div className="text">
              <div className="line1"></div>
              <h2>Learn how to adopt and scale AI and drive business value</h2>
            </div>
          </div>
        </div>
      </>
    );
}

export default Banner