import React from 'react'

function Scale() {
    return (
        <div>
            <div className="Scale">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="">
                                <center>
                                    <h1>92</h1>
                                    <p>Pathfinders</p>
                                </center>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="">
                                <center>
                                    <h1>10k+</h1>
                                    <p>Businesses</p>
                                </center>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="">
                                <center>
                                    <h1>7</h1>
                                    <p>Countries</p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scale