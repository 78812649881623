import React, { useEffect, useState } from 'react'
import axios from "axios";
import LinkNav from '../../components/LinkNav'
import $ from 'jquery';
// import ContactUs from '../../components/ContactUs';
// import { Link } from 'react-router-dom';
import GetStartedModal from '../../components/GetStartedModal';

function GetInTouch() {

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        axios
            .get("https://restcountries.com/v3.1/all")
            .then((response) => {
                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    const Email = () => {
        var $target = $('.email').next('.email');
        if ($target.length === 0)
            $target = $('.email');

        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 'slow');
    }

    const Social = () => {
        var $target = $('.social').next('.social');
        if ($target.length === 0)
            $target = $('.social');

        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 'slow');
    }

    const Community = () => {
        var $target = $('.community').next('.community');
        if ($target.length === 0)
            $target = $('.community');

        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 'slow');
    }

    return (
        <div>
            <div className="getintouch">
                <LinkNav
                    FirstUrl="/company"
                    First="Company"
                    Dash="|"
                    SecondUrl="/getintouch"
                    Second="Get in touch"
                />
                <div className="bannerr1">
                    <h2 className='animate__animated animate__bounce'>GET IN TOUCH </h2>

                    <div className="flex">
                        <button className='upper ' onClick={Email}>Via Email</button>
                        <button className='down' onClick={Social}>Social Media</button>
                        <button className='side' onClick={Community}>Community Forum</button>
                    </div>

                </div>

                <div className="margin50"></div>
                <center>
                    <div className="container">
                        <div className="col-md-9 ready email">
                            <p>"Ready to embark on your AI journey? Let's collaborate and shape the future together. Reach out to us and explore how our AI solutions can pave the way for your success. We're here to guide you through every step of your technological evolution. Connect with us today and unlock the endless possibilities of AI."</p>
                        </div>
                    </div>
                </center>
                <div className="seection1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-7 ">
                                <h2>Via Email</h2>
                                <form action="" className=''>
                                    <div class="form-floating mb-3">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="floatingName"
                                            placeholder="Name"
                                        />
                                        <label for="floatingName">Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input
                                            type="email"
                                            class="form-control"
                                            id="floatingInput"
                                            placeholder="name@example.com"
                                        />
                                        <label for="floatingInput">Email address</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div class="form-floating mb-3">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="floatingInput"
                                                    placeholder="19"
                                                />
                                                <label for="floatingInput">Age</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <select class="form-select" id="floatingSelectGrid">
                                                    <option selected>Please Select</option>
                                                    <option value="1">I am a journalist</option>
                                                    <option value="2">I am a donor</option>
                                                    <option value="3">I am a student</option>
                                                    <option value="2">I want to make a difference</option>
                                                    <option value="3">I am an educator/scientist</option>
                                                    <option value="2">I'm just having a look around</option>
                                                    <option value="3">I'd like to work with WWF</option>
                                                    <option value="2">I am a policy maker</option>
                                                    <option value="3">I am a businessman / businesswoman</option>
                                                    <option value="2">I am a working professional.</option>
                                                </select>
                                                <label for="floatingSelectGrid">What best describes you?</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="floatingSelectGrid">
                                            <option selected>Please Select</option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country.name.common}>
                                                    {country.name.common}
                                                    <img
                                                        src={country.flag}
                                                        alt={country.name.common + " flag"}
                                                        style={{ width: "20px", marginLeft: "10px" }}
                                                    />
                                                </option>

                                            ))}

                                        </select>
                                        <label for="floatingSelectGrid">Country</label>
                                    </div>
                                    <div class="form-floating mb-4">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="floatingMessage"
                                            placeholder=""
                                        />
                                        <label for="floatingMessage">Company domain name</label>
                                    </div>
                                    <div class="form-check mb-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I would like to receive news about conservation, fundraisers, campaigns and events from WWF. You can unsubscribe from these emails at any time. For more information, please see our <span>Privacy Statement.*</span>
                                        </label>
                                    </div>

                                    <button className='btn btnsGreen '>Submit</button>
                                </form>
                            </div>
                            <div className="col-md-3 ">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin50 "></div>
                {/* <div className="seection2 ">
                    <div className="container">
                        <center>
                            <div className="col-md-6">
                                <h2>Social Media</h2>
                                <p>Stay connected and up-to-date with AI Pathfinder through our social media channels. You can follow us on [Social Media Links] to access the latest news, events, and discussions.</p>
                            </div>
                        </center>
                        <div className="social">
                            <div className="row">
                                <div className="col-md-3">
                                    <center>
                                        <div className="innit">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                            </svg>
                                            <h1>Twitter</h1>
                                        </div>
                                    </center>
                                </div>
                                <div className="col-md-3">
                                    <center>
                                        <div className="innit">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                            </svg>
                                            <h1>Facebook</h1>
                                        </div>
                                    </center>
                                </div>
                                <div className="col-md-3">
                                    <center> <div className="innit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                        </svg>
                                        <h1>Instagram</h1>
                                    </div>
                                    </center>
                                </div>
                                <div className="col-md-3">
                                    <center>
                                        <div className="innit">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                            </svg>
                                            <h1>Linkedln</h1>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="seection3 community">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h2>Community <br /> Forum</h2>
                                <p></p>
                            </div>
                            <div className="col-md-6">
                                <p>Join our community and participate in discussions on our forum. It's a great place to interact with other AI enthusiasts and find answers to your questions.</p>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-2">
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont">
                    <div className="container">

                        <div className="row">
                        <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="sign">
                                   <center>
                                   <h2>Sign up And become a <br /> Pathfinder </h2>
                                    <GetStartedModal />
                                   </center>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>

                    </div>
                </div>
                <div className="seection4">
                    <div className="container">
                        <center>
                            <div className="col-md-6">
                                <div className="thank">
                                    <h2>Thank You</h2>
                                    <p>Thank you for your interest in AI Pathfinder. We look forward to hearing from you and providing the information and support you need.</p>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            <div className="social"></div>
        </div>
    )
}

export default GetInTouch