import React from 'react';
import { Container } from '@chakra-ui/react';

const MainContainer = ({ children }) => {
  return (
    <Container
      maxW={{ base: '100%', md: '90%', xl: '1200px' }}
      m="0 auto"
      p={{ base: '1rem 1rem', md: '2rem 1rem', xl: '2rem 4rem' }}
      w="100%"
    >
      {children}
    </Container>
  );
};

export default MainContainer;